<div class="w-100">
    <ng-template [ngIf]="s_staffList?.jobDurationDetailsList?.length > 0" [ngIfElse]="else_temp">
        
      <div class="border-bottom" [style.border-color]="s_staffList.jobColor">
        <app-cust-short-job-type-dts [shjobTypeDtsInput]="{
                        date         : s_staffList?.jobDateFixedFormat ,
                        customerName : s_staffList.customerName,
                        customerLogo : s_staffList.customerLogo,
                        customerCode : s_staffList.customerCode,
                        siteName     : s_staffList.siteName,  
                        siteAddress  : s_staffList.siteAddress, 
                        jobTypeName  : s_staffList.jobTypeName,
                        jobTypeIcon  : s_staffList.jobTypeIcon,
                        rateCard     : s_staffList.showRateCard,  
                        jobTypeColor : s_staffList.jobColor    
                      }">
        </app-cust-short-job-type-dts>

        <div class="div-border mt-1 mb-1"></div>

    <app-shift-with-job-type
    [shiftWithJobTypeInput]="{
         jobTypeWithShiftList : s_staffList,
         customerName         : s_staffList.customerName ,
         customerLogo         : s_staffList.customerLogo,
         customerCode         : s_staffList.customerCode,
         siteName             : s_staffList.siteName ,
         siteAddress          : s_staffList.siteAddress ,
         appointmentsCode     : s_staffList.appointmentsCode,
         siteAddressCode      : s_staffList.siteAddressCode,
         jobDateFixedFormat   : s_staffList.jobDateFixedFormat,
         date                 : s_staffList.jobDateFixedFormat
    }"

    [status]="status"
    [allowScheduledStaff_Icon]="false"
    [allowActions]="allowActions"
    [allowBreaks]="false">
</app-shift-with-job-type>


    </div>
  
        <div class="table-scroll-height" infiniteScroll [infiniteScrollDistance]="2"
          [infiniteScrollThrottle]="50" (scrolled)="onScrollStaff()" [scrollWindow]="false" [fromRoot]="true">
          <ng-container *ngFor="let staff of s_staffList.jobDurationDetailsList">
            <div class="row w-100 m-0">
            <admin-scheduled-staff-card
             [parent_shiftDetails]="{
                        appointmentsCode : s_staffList.appointmentsCode,
                        appointmentsProceduresCode : s_staffList.appointmentsProceduresCode,
                        customerName  : s_staffList.customerName,
                        customerLogo  : s_staffList.customerLogo,
                        customerCode  : s_staffList.customerCode,
                        startTime      : s_staffList.startTime,
                        endTime         : s_staffList.endTime,
                        jobDateFixedFormat  : s_staffList.jobDateFixedFormat
             }"
             [scheduleStaff_Input_Data]="staff" 
             (emitter_refreshList)="refreshData($event)">
            </admin-scheduled-staff-card>
          </div>
          </ng-container>
        </div>
      </ng-template>

      <ng-template #else_temp>
      <div class="row d-flex align-items-center spinner" *ngIf="_commonSVC.progressLoader">
        <div class="col-lg-1 text-end">
          <mat-spinner></mat-spinner>
        </div>
        <div class="col-lg-8 text-start"><span>Please Wait ...</span></div>
      </div>
  
      <div class="row" *ngIf="!_commonSVC.progressLoader && s_staffList?.jobDurationDetailsList?.length === 0">
        <div>
          <span class="text-danger">No Record Available</span>
        </div>
      </div>
    </ng-template>
</div>