<div class="w-100">
  <div class="col-12">
    <div *ngIf="patientForm.controls?.patientName?.value" class="short-details">
    <img class="card-img-top" alt="User Image"
    [src]="patientForm.controls?.customerLogo?.value ? image_path+patientForm.controls?.customerLogo?.value : 'assets/common_images/profile.jpg'">
     <span>{{patientForm.controls?.patientName?.value}}</span>
  </div>
  <button *ngIf="data?.customerCode" class="btn-close cursor-pointer" (click)="closeDialog()"></button>
  </div>
  <mat-tab-group
    dynamicHeight
    class="tab-button-auto-width"
    (selectedTabChange)="tabChanged($event)"
  >
    <mat-tab label="Details">
      <ng-container *ngIf="tabAtciveFor === 'Details'">
        <div class="w-100 pt-2">
          <div
            class="d-flex flex-row justify-content-between w-100 logo align-items-center"
          >
            <div class="float-start header-name">
              <span>{{ "Customers.labels.Customer" | translate }}</span>
              <span class="headerNoteTitleBtn">
                <span class="ps-4 cursor-pointer" (click)="service?.customerData?.noteList ? service.common.addUpdateNotes(service.customerData.noteList) : service.common.addUpdateNotes()">
                  <span class="notebtnContainer" *ngIf="patientForm.controls.customerCode.value && service.customerData?.noteList?.length > 0"><img class="p-1 notebtnIc" src="assets/images/note-icon-active.svg" /><span class="ps-1 noteBtnTxtActive">Note</span></span>
                  <span class="notebtnContainer" *ngIf="!patientForm.controls.customerCode.value || service.customerData?.noteList?.length === 0"><img class="p-1 notebtnIc" src="assets/images/note-icon.svg" /><span class="ps-1 noteBtnTxtInactive">Note</span></span>
                </span>
              </span>
            </div>
            <div class="ms-auto p-2">
              <div class="text-center d-flex">
                <button *ngIf="!customerCode"
                  class="mb-2 me-2"
                  mat-raised-button
                  color="primary"
                  (click)="saveCustomerDetails(true)" matTooltip="Save and send welcome email">Save & Send</button>
                <button
                  class="mb-2"
                  mat-raised-button
                  color="primary"
                  (click)="saveCustomerDetails(false)" >
                  {{patientForm.controls.customerCode.value ? 'Update Details' : 'Save Details'}}
                  <!-- {{ "Customers.labels.Save Changes" | translate }} -->
                </button>
                <button *ngIf="!data?.customerCode" class="ms-2" mat-button (click)="cancelPopup()">
                  {{ "Technicians.labels.Cancel" | translate }}
                </button>
              </div>
            </div>
          </div>
          <div class="w-100 form-card">
            <div class="row">
              <h6 class="mini-header"> Customer Details</h6>
             </div>
            <!-- <form [formGroup]="patientForm" autocomplete="off" (ngSubmit)="onSubmit()" > -->
              <form [formGroup]="patientForm" autocomplete="off" >
              <div class="row">
                <div class="col-3">
                  <mat-form-field
                    appearance="outline"
                    floatLabel="always"
                    class="example-full-width"
                  >
                    <mat-label>{{
                      "Customers.labels.Customer Name" | translate
                    }}</mat-label>
                    <input
                      matInput
                      class="form-control"
                      formControlName="patientName"
                      maxlength="50"
                     />
                  </mat-form-field>
                  <span
                    class="help-block err"
                    *ngIf="
                      patientForm.get('patientName').errors?.required &&
                      (isCustomerForm_Submitted || patientForm.get('patientName').touched)
                    "
                    >{{
                      "Customers.validators.Customer name is required"
                        | translate
                    }}</span
                  >
                </div>
                <div class="col-3">
                  <mat-form-field
                    appearance="outline"
                    floatLabel="always"
                    class="example-full-width"
                  >
                    <mat-label>{{
                      "Customers.labels.Customer Email" | translate
                    }}</mat-label>
                    <input
                      matInput
                      class="form-control"
                      formControlName="patientEmail"
                      maxlength="50"
                      />
                  </mat-form-field>
                  <span
                    class="help-block err"
                    *ngIf="
                      patientForm.get('patientEmail').errors?.required &&
                      (isCustomerForm_Submitted || patientForm.get('patientEmail').touched)
                    "
                    >{{
                      "Customers.validators.Customer email is required"
                        | translate
                    }}</span
                  >
                  <span
                    class="help-block err" *ngIf="patientForm.controls.patientEmail.errors?.invalid && (!isCustomerForm_Submitted || patientForm.controls.patientEmail.touched)">
                    {{ 'registration.provider.validators.Email id is already present' | translate }}.</span>
                </div>

                <div class="col-3">
                  <mat-form-field appearance="outline" floatLabel="always" class="example-full-width"  >
                    <mat-label>Phone No</mat-label>
                    <input matInput class="form-control" appPhoneMask  maxlength="12" formControlName="mainPhone" />
                  </mat-form-field>
                  
                  <span class="help-block err"
                  *ngIf=" patientForm.get('mainPhone').errors?.required &&
                    (isCustomerForm_Submitted || patientForm.get('mainPhone').touched)">Please enter phone no</span>

                    <span class="help-block err"
                    *ngIf=" patientForm.get('mainPhone').errors?.pattern &&
                      (isCustomerForm_Submitted || patientForm.get('mainPhone').touched)">Not a valid phone no</span>

                  </div>
    
                  <div class="col-3">
                    <mat-form-field appearance="outline" floatLabel="always" class="example-full-width"  >
                      <mat-label>Fax</mat-label>
                      <input matInput class="form-control" formControlName="mainFax" maxlength="50" />
                    </mat-form-field>
                    </div>

                    <div class="col-12">
                      <mat-form-field appearance="outline" floatLabel="always" class="example-full-width"  >
                      <mat-label>Customer Address</mat-label>
                      <input matInput class="form-control" formControlName="customerLocation" placeholder="Enter a address" ngx-google-places-autocomplete
                      [options]='options'  (onAddressChange)="CustomerAddressChange($event)"/>
                    </mat-form-field>
                    </div>

                 
                       <div class="col-2">
                         <mat-form-field appearance="outline" floatLabel="always" class="example-full-width"  >
                         <mat-label>Customer State</mat-label>
                         <input matInput class="form-control" formControlName="customerState" />
                       </mat-form-field>
                       </div>
       
                       <div class="col-2">
                         <mat-form-field appearance="outline" floatLabel="always" class="example-full-width"  >
                         <mat-label>Customer City</mat-label>
                         <input matInput class="form-control" formControlName="customerCity" />
                       </mat-form-field>
                       </div>
       
                       <div class="col-2">
                         <mat-form-field appearance="outline" floatLabel="always" class="example-full-width"  >
                         <mat-label>Zip Code</mat-label>
                         <input matInput class="form-control" formControlName="zipCode" maxlength="15"/>
                       </mat-form-field>
                       </div>

                                        
                  <div class="col-3">
                    <mat-form-field appearance="outline" floatLabel="always" class="example-full-width"  >
                      <mat-label>Primary Contact Person</mat-label>
                      <input matInput class="form-control" formControlName="primaryContact" />
                    </mat-form-field>
                    </div>
    
                    <div class="col-3">
                      <mat-form-field appearance="outline" floatLabel="always" class="example-full-width"  >
                        <mat-label>Primary Contact Phone</mat-label>
                        <input matInput class="form-control" appPhoneMask maxlength="12" formControlName="primaryContactPhone" />
                      </mat-form-field>
                      <span class="help-block err"
                      *ngIf=" patientForm.get('primaryContactPhone').errors?.pattern &&
                        (isCustomerForm_Submitted || patientForm.get('primaryContactPhone').touched)">Not a valid phone no</span>
                      </div>


              </div>
        
              <!-- <div class="row">
                <div class="col-md-4 col-sm-12">
                  <div class="row">
                    <div class="col-md-5">
                      <app-country-code
                        class="ccode country-code"
                        placeholder="Mobile Number"
                        (setCountryCode)="
                          getCountryCode($event, 'mobileCountryCode')
                        "
                        [setCode]="mobileCountryCode"
                        [placeholder]="'Country'"
                      ></app-country-code>
                      <span
                        class="help-block"
                        *ngIf="
                          patientForm.get('mobileCountryCode').errors
                            ?.required &&
                          (isSiteForm_submitted ||
                            patientForm.get('mobileCountryCode').touched)
                        "
                      >
                        {{
                          "Customers.validators.Country code is required"
                            | translate
                        }}</span
                      >
                    </div>
                    <div class="col-md-7">
                      <mat-form-field
                        appearance="outline"
                        floatLabel="always"
                        class="example-full-width p-0 no-form"
                      >
                        <mat-label>Home No</mat-label>
                        <input
                          autocomplete="off"
                          matInput
                          class="form-control"
                          placeholder=""
                          formControlName="phoneNumber"
                          [(ngModel)]="service.patientData.phoneNumber"
                        />
                      </mat-form-field>

                      <span
                        class="help-block err"
                        *ngIf="
                          patientForm.get('phoneNumber').errors?.required &&
                          (isSiteForm_submitted || patientForm.get('phoneNumber').touched)
                        "
                        >{{
                          "Customers.validators.Phone Number is required"
                            | translate
                        }}</span
                      >
                      <span
                        class="help-block err"
                        *ngIf="
                          patientForm.get('phoneNumber').errors?.pattern &&
                          (isSiteForm_submitted || patientForm.get('phoneNumber').touched)
                        "
                        >{{
                          "Customers.validators.Please enter valid phone number"
                            | translate
                        }}</span
                      >
                      <span
                        class="help-block err"
                        *ngIf="
                          (patientForm.get('phoneNumber').errors?.minlength ||
                            patientForm.get('phoneNumber').errors?.maxlength) &&
                          (isSiteForm_submitted || patientForm.get('phoneNumber').touched)
                        "
                        >{{
                          "Customers.validators.phone number should be 10 digit"
                            | translate
                        }}</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-sm-12">
                  <div class="row">
                    <div class="col-md-5">
                      <app-country-code
                        class="ccode country-code"
                        placeholder="Mobile Number"
                        [disabled]="true"
                        (setCountryCode)="
                          getCountryCode($event, 'mobileCountryCode')
                        "
                        [setCode]="mobileCountryCode"
                        [placeholder]="'Country'"
                      ></app-country-code>
                      <span
                        class="help-block"
                        *ngIf="
                          patientForm.get('mobileCountryCode').errors
                            ?.required &&
                          (isSiteForm_submitted ||
                            patientForm.get('mobileCountryCode').touched)
                        "
                      >
                        {{
                          "Customers.validators.Country code is required"
                            | translate
                        }}</span
                      >
                    </div>

                    <div class="col-md-7">
                      <mat-form-field
                        appearance="outline"
                        floatLabel="always"
                        class="example-full-width p-0 no-form"
                      >
                        <mat-label>Mobile No</mat-label>
                        <input
                          autocomplete="off"
                          class="form-control"
                          matInput
                          placeholder=""
                          formControlName="mobileNumber"
                          [(ngModel)]="service.patientData.mobileNumber"
                        />
                      </mat-form-field>

                      <span
                        class="help-block err"
                        *ngIf="
                          patientForm.get('mobileNumber').errors?.required &&
                          (isSiteForm_submitted ||
                            patientForm.get('mobileNumber').touched)
                        "
                        >{{
                          "Customers.validators.Mobile Number is required"
                            | translate
                        }}</span
                      >
                      <span
                        class="help-block err"
                        *ngIf="
                          patientForm.get('mobileNumber').errors?.pattern &&
                          (isSiteForm_submitted ||
                            patientForm.get('mobileNumber').touched)
                        "
                        >{{
                          "Customers.validators.Please enter valid Mobile number"
                            | translate
                        }}</span
                      >
                      <span
                        class="help-block"
                        *ngIf="
                          (patientForm.get('mobileNumber').errors?.minlength ||
                            patientForm.get('mobileNumber').errors
                              ?.maxlength) &&
                          (isSiteForm_submitted ||
                            patientForm.get('mobileNumber').touched)
                        "
                        >{{
                          "Customers.validators.Mobile number should be 10 digit"
                            | translate
                        }}</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-sm-12">
                  <div class="row">
                    <div class="col-md-5">
                      <app-country-code
                        class="ccode country-code"
                        placeholder="Mobile Number"
                        (setCountryCode)="
                          getCountryCode($event, 'mobileCountryCode')
                        "
                        [setCode]="mobileCountryCode"
                        [placeholder]="'Country'"
                      ></app-country-code>
                      <span
                        class="help-block"
                        *ngIf="
                          patientForm.get('mobileCountryCode').errors
                            ?.required &&
                          (isSiteForm_submitted ||
                            patientForm.get('mobileCountryCode').touched)
                        "
                      >
                        {{
                          "Customers.validators.Country code is required"
                            | translate
                        }}</span
                      >
                    </div>

                    <div class="col-md-7">
                      <mat-form-field
                        appearance="outline"
                        floatLabel="always"
                        class="example-full-width p-0 no-form"
                      >
                        <mat-label>Additional Contact No</mat-label>
                        <input
                          autocomplete="off"
                          class="form-control"
                          matInput
                          placeholder=""
                          formControlName="preferredNumber"
                          [(ngModel)]="service.patientData.preferredNumber"
                        />
                      </mat-form-field>

                      <span
                        class="help-block err"
                        *ngIf="
                          patientForm.get('preferredNumber').errors?.required &&
                          (isSiteForm_submitted ||
                            patientForm.get('preferredNumber').touched)
                        "
                        >{{
                          "Customers.validators.Preferred Number is required"
                            | translate
                        }}</span
                      >
                      <span
                        class="help-block err"
                        *ngIf="
                          patientForm.get('preferredNumber').errors?.pattern &&
                          (isSiteForm_submitted ||
                            patientForm.get('preferredNumber').touched)
                        "
                        >{{
                          "Customers.validators.Please enter valid Preferred number"
                            | translate
                        }}</span
                      >
                      <span
                        class="help-block"
                        *ngIf="
                          (patientForm.get('preferredNumber').errors
                            ?.minlength ||
                            patientForm.get('preferredNumber').errors
                              ?.maxlength) &&
                          (isSiteForm_submitted ||
                            patientForm.get('preferredNumber').touched)
                        "
                        >{{
                          "Customers.validators.Preferred number should be 10 digit"
                            | translate
                        }}</span
                      >
                    </div>
                  </div>
                </div>
              </div> -->

            <div class="row">
              <div class="col-3">
               <h6 class="mini-header"> Billing Details</h6>
               </div>
               <div class="col-lg-4">
                <span class="fst-italic">
                  <input type="checkbox" [disabled]="!(patientForm.controls['patientEmail'].value && patientForm.controls['mainPhone'].value)"
                   (change)="handle_SameAsAbove($event)">&nbsp;
                  Same as above
                </span>
               </div>
               
              </div>

              <div class="row">
 

            <div class="col-3">
            <mat-form-field appearance="outline" floatLabel="always" class="example-full-width"  >
              <mat-label>Billing Email</mat-label>
              <input matInput class="form-control" formControlName="billingEmail" maxlength="50"/>
            </mat-form-field>
            <span class="help-block err"
              *ngIf=" patientForm.get('billingEmail').errors?.required&&
              (isCustomerForm_Submitted || patientForm.get('billingEmail').touched)">Billing email is required</span>

              <span class="help-block err"
              *ngIf=" patientForm.get('billingEmail').errors?.email &&
              (isCustomerForm_Submitted || patientForm.get('billingEmail').touched)">Not a valid email</span>
              <span class="help-block err" *ngIf="patientForm.controls.billingEmail.errors?.invalid && (!isCustomerForm_Submitted || patientForm.controls.billingEmail.touched)">
                {{ 'registration.provider.validators.Email id is already present' | translate }}.</span>

            </div>

            <div class="col-3">
              <mat-form-field appearance="outline" floatLabel="always" class="example-full-width"  >
                <mat-label>Billing Phone</mat-label>
                <input matInput class="form-control" appPhoneMask maxlength="12" formControlName="billingPhone" />
              </mat-form-field>
              <span class="help-block err"
              *ngIf=" patientForm.get('billingPhone').errors?.required&&
              (isCustomerForm_Submitted || patientForm.get('billingPhone').touched)">Billing Phone no is required</span>
              <span class="help-block err"
              *ngIf=" patientForm.get('billingPhone').errors?.pattern &&
              (isCustomerForm_Submitted || patientForm.get('billingPhone').touched)">Not a valid phone no</span>
              </div>

              <div class="col-3">
                <mat-form-field appearance="outline" floatLabel="always" class="example-full-width"  >
                  <mat-label>Billing Contact Person</mat-label>
                  <input matInput class="form-control" formControlName="billingContact" />
                </mat-form-field>
                </div> 
                
                <div class="col-3">
              <mat-form-field appearance="outline" floatLabel="always" class="example-full-width"  >
                <mat-label>Billing Contact Phone</mat-label>
                <input matInput class="form-control" appPhoneMask maxlength="12" formControlName="billingContactPhone" />
              </mat-form-field>
              <span class="help-block err"
              *ngIf=" patientForm.get('billingContactPhone').errors?.pattern &&
              (isCustomerForm_Submitted || patientForm.get('billingContactPhone').touched)">Not a valid phone no</span>
              </div>

                <div class="col-12">
                    <mat-form-field appearance="outline" floatLabel="always" class="example-full-width"  >
                    <mat-label>Billing Address</mat-label>
                    <input matInput class="form-control" placeholder="Enter billing address" formControlName="billingLocation" ngx-google-places-autocomplete
                    [options]='options' (onAddressChange)="BillingAddressChange($event)"/>
                  </mat-form-field>
                  </div>
        

            <div class="col-3">
              <mat-form-field appearance="outline" floatLabel="always" class="example-full-width"  >
              <mat-label>Billing State</mat-label>
              <input matInput class="form-control" formControlName="billingState" />
            </mat-form-field>
            </div>

            <div class="col-3">
              <mat-form-field appearance="outline" floatLabel="always" class="example-full-width"  >
              <mat-label>Billing City</mat-label>
              <input matInput class="form-control" formControlName="billingCity" />
            </mat-form-field>
            </div>

            <div class="col-3">
              <mat-form-field appearance="outline" floatLabel="always" class="example-full-width"  >
              <mat-label>Zip Code</mat-label>
              <input matInput class="form-control" formControlName="billingZipCode" maxlength="15"/>
            </mat-form-field>
            </div>

            <div class="col-3">
              <mat-form-field appearance="outline" floatLabel="always" class="example-full-width"  >
              <mat-label>Tax(%)</mat-label>
              <input matInput type="number" class="form-control" formControlName="billingTax" [min]="00.00" [max]="100.00" [step]="00.01" />
            </mat-form-field>
            <span class="help-block err" *ngIf="patientForm.get('billingTax').touched && patientForm.get('billingTax').errors?.pattern">Please fill a valid tax value</span>
            </div>
            
          </div>
 
          <!-- <ng-template [ngIf]="customerCode">
          <hr>
          <div class="row">
            <div class="col-12">
              <h6 class="mini-header">Staff Exceptions</h6>
              </div>

              <div class="col-3">
                <mat-form-field appearance="outline" floatLabel="always">
                  <mat-label>Staff</mat-label>
                  <mat-select formControlName="exc_staff">
                    <mat-option>
                <ngx-mat-select-search  [placeholderLabel]="'Search staff'"  [noEntriesFoundLabel]="'Staff not found'"
                [formControl]="staffSeachInput"></ngx-mat-select-search>
              </mat-option>
                    <mat-option *ngFor="let staff of staffList_Filter"  [value]="staff">
                      {{staff.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="col-3">
                <mat-form-field appearance="outline" floatLabel="always">
                  <mat-label>{{ 'main.labels.Job Site' | translate }}</mat-label>
                  <mat-select formControlName="exc_jobSite" multiple>
                    <app-select-check-all [model]="patientForm.get('exc_jobSite')" [values]="customerSiteList">
                    </app-select-check-all>
                   <mat-option *ngFor="let site of customerSiteList" [value]="site">
                      {{site.siteName}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              
        <div class="col-3">
          <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>Notes</mat-label>
            <input matInput class="form-control" formControlName="excpNotes">
          </mat-form-field>
        </div>

              <div class="col-3 text-center">
                <button mat-raised-button color="primary" (click)="addStaffInBlockList()"
                [disabled]="(!patientForm.controls.exc_staff.value && !patientForm.controls.exc_jobSite.value)">Add</button>
              </div>

          </div>

          <div class="row" *ngIf="blockedStaffs.length > 0">
       
           <div class="col-12">
            <div class="simple-table">
           <table>
            <thead>
              <tr>
                <td class="w-50">Staff</td>
                <td class="w-25">{{ 'main.labels.Job Site' | translate }}</td>
                <td>Notes</td>
                <td>Action</td>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let blockStaff of blockedStaffs">
               <td>
                <img class="blocked-staff-img" alt="User Image"
                     [src]="blockStaff.workerLogo ? image_path+blockStaff.workerLogo  : 'assets/common_images/profile.jpg'">
                &nbsp;{{blockStaff.name}}</td>
               <td>{{blockStaff.siteName}}</td> 
               <td>{{blockStaff.note}}</td>
               <td><mat-icon class="text-danger" (click)="removeStaffFromBlockList(blockStaff)">delete</mat-icon></td>
              </tr>
            </tbody>
           </table>
          </div>
           </div>
          </div>
        </ng-template> -->


            </form>
          </div>
        </div>
      </ng-container>
    </mat-tab>
    
  <ng-container *ngIf="patientForm.controls.customerCode.value">
    <mat-tab label="{{ 'main.labels.Job Site' | translate }}">
      <ng-container *ngIf="tabAtciveFor === 'Job Site'">
        <add-location [customerCode]="patientForm.controls.customerCode.value"></add-location>
      </ng-container>
    </mat-tab>
  </ng-container>

    <ng-container *ngIf="patientForm.controls.customerCode.value">
      <mat-tab label="Contacts">
        <ng-container *ngIf="tabAtciveFor === 'Contacts'">
          <div class="w-100">
            <user-registration
              [customerCode]="patientCode"
              [isCustomerLoggedIn]="false"
            ></user-registration>
          </div>
        </ng-container>
      </mat-tab>
    </ng-container>

    <ng-container *ngIf="patientForm.controls.customerCode.value">
    <mat-tab label="Rate Card">
      <ng-container *ngIf="tabAtciveFor === 'Rate Card'">
        <job-rate-card [customerCode]="patientCode"></job-rate-card>
      </ng-container>
    </mat-tab>
  </ng-container>

  <ng-container *ngIf="patientForm.controls.customerCode.value">
  <mat-tab label="Staff Exceptions">
    <ng-container *ngIf="tabAtciveFor === 'Staff Exceptions'">
      <ng-template [ngIf]="customerCode">
        <form [formGroup]="patientForm" autocomplete="off" >
        <div class="row">
          <div class="col-12 pt-4 pb-2">
            <span class="header-name">Staff Exceptions</span>
            </div>

            <div class="col-3">
              <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>Staff</mat-label>
                <mat-select formControlName="exc_staff">
                  <mat-option>
              <ngx-mat-select-search  [placeholderLabel]="'Search staff'"  [noEntriesFoundLabel]="'Staff not found'"
              [formControl]="staffSeachInput"></ngx-mat-select-search>
            </mat-option>
                  <mat-option *ngFor="let staff of staffList_Filter"  [value]="staff">
                    {{staff.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-4">
              <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>{{ 'main.labels.Job Site' | translate }}</mat-label>
                <mat-select formControlName="exc_jobSite" multiple>
                  <app-select-check-all [model]="patientForm.get('exc_jobSite')" [values]="customerSiteList">
                  </app-select-check-all>
                 <mat-option *ngFor="let site of customerSiteList" [value]="site">
                    {{site.siteName}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            
      <div class="col-4">
        <mat-form-field appearance="outline" floatLabel="always">
          <mat-label>Notes</mat-label>
          <input matInput class="form-control" formControlName="excpNotes">
        </mat-form-field>
      </div>

            <div class="col-1">
              <div class="row text-end pe-3">
                <button mat-raised-button color="primary" (click)="addStaffInBlockList()"
              [disabled]="(!patientForm.controls.exc_staff.value && !patientForm.controls.exc_jobSite.value)">Add</button>
              </div>              
            </div>

        </div>

        <div class="row">
     
         <div class="col-12">
          <div class="table-scroll-height">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 table-responsiv position-relative">

            <ng-container matColumnDef="name" class="dis-icon">
              <th mat-header-cell *matHeaderCellDef class="text-nowrap">Staff</th>
              <td mat-cell *matCellDef="let element" class="text-nowrap popoverToolTipLineHeight">
                <profile-avatar-with-name [userDetail]="element" [isRowNameWithLogo]="true" [popoverShowFor]="'staff'" [isProfileButtonEnable]="true" [isChatButtonEnable]="true"></profile-avatar-with-name>
              </td>
            </ng-container>

            <ng-container matColumnDef="jobSite" class="dis-icon">
              <th mat-header-cell *matHeaderCellDef class="text-nowrap">{{ 'main.labels.Job Site' | translate }}</th>
              <td mat-cell *matCellDef="let element; let idx = index"> {{element.siteName}} </td>
            </ng-container>

            <ng-container matColumnDef="notes" class="dis-icon">
              <th mat-header-cell *matHeaderCellDef>Note</th>
              <td mat-cell *matCellDef="let element"> {{element.note}} </td>
            </ng-container>

            <ng-container matColumnDef="action" class="dis-icon">
              <th mat-header-cell *matHeaderCellDef class="text-center">Action</th>
              <td mat-cell *matCellDef="let element" class="text-center">
                <mat-icon class="text-danger" (click)="removeStaffFromBlockList(element)">delete</mat-icon>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
              <div *ngIf="!blockedStaffs || blockedStaffs.length === 0" class="p-3 noList text-danger">No records found</div>
         </div>
        </div>
        </div>
      </form>
      </ng-template>
    </ng-container>
  </mat-tab>
</ng-container>

<ng-container *ngIf="patientForm.controls.customerCode.value">
  <mat-tab label="Documents">
    <ng-container *ngIf="tabAtciveFor === 'Documents'">
      <ng-template [ngIf]="customerCode">
        <common-add-view-documents [customerOrStaffCode]="customerCode" [isCustomer]="'true'"></common-add-view-documents>
      </ng-template>
    </ng-container>
  </mat-tab>
</ng-container>
</mat-tab-group>
</div>
