import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { TechnicianService } from '../../dashboards/providers-dashboard/technician/technician.service';
import { SessionStorageService } from 'angular-web-storage';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'popover-content',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
  host: {'class': 'popoverCls'},
  // template: `
  //   <div class="popover-content" (click)="$event.stopPropagation()">
  //     <ng-container *ngTemplateOutlet="template"></ng-container>
  //     <button (click)="closePopover()">Close</button>
  //   </div>
  // `,
  // styles: [`
  //   .popover-content {
  //     border: 1px solid #ccc;
  //     padding: 10px;
  //     background: white;
  //     box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  //   }
  // `]
})
export class PopoverComponent implements OnInit {
  fileUrl: any;
  @Input() appPopoverData: any;
  @Input() marginLeft: number;
  // @Input() template: TemplateRef<any>;
  @Output() close = new EventEmitter<void>();
  isChatProfileOpen: boolean = false;
  isProfileButtonEnable: boolean = false;
  isChatButtonEnable: boolean = false;
  // isProfileButtonEnable: this.isProfileButtonEnable === true ? true : false,
  // isChatButtonEnable: this.isChatButtonEnable === true ? true : false,

  private loggedInUserType:string = '' ;

  constructor(
    public technicianService: TechnicianService, public session: SessionStorageService, public router: Router, public route: ActivatedRoute,) {}

  ngOnInit() {
    // this.isChatProfileOpen = this.session.get('user_type') && this.session.get('user_type') === 'Customer' ? false : (this.appPopoverData?.staffCode || this.appPopoverData?.workerCode || this.appPopoverData?.userCode || this.appPopoverData?.code) ? true : false;

    // console.log('AppPopoverData:', this.appPopoverData);
    // let isChatOpen = false;
    // if((this.appPopoverData?.isChatProfileOpen === undefined || this.appPopoverData?.isChatProfileOpen === true) && this.session.get('user_type') !== 'Customer' && (this.appPopoverData?.staffCode || this.appPopoverData?.workerCode || this.appPopoverData?.userCode || this.appPopoverData?.code || this.appPopoverData?.customerCode)){
    //   isChatOpen = true;
    // } else {
    //   isChatOpen = false;
    // }
    // this.isChatProfileOpen = isChatOpen;
    this.loggedInUserType = this.session.get("user_type");

    this.isProfileButtonEnable = this.appPopoverData?.isProfileButtonEnable;

    if(this.loggedInUserType === 'Customer')
    {
      this.isChatButtonEnable = false;
     }
    else{
      this.isChatButtonEnable = this.appPopoverData?.isChatButtonEnable;
    }

    

    // this.isChatProfileOpen = this.session.get('user_type') && this.session.get('user_type') === 'Customer' ? false : ((this.appPopoverData?.isProfileButtonEnable || this.appPopoverData?.isChatButtonEnable) && (this.appPopoverData?.staffCode || this.appPopoverData?.customerCode)) ? true : false;

    this.isChatProfileOpen = (this.loggedInUserType === 'Customer' && this.appPopoverData.popoverShowFor === 'customer') ? false : ((this.appPopoverData?.isProfileButtonEnable || this.appPopoverData?.isChatButtonEnable)
                             && (this.appPopoverData?.staffCode || this.appPopoverData?.customerCode))
                              ? true
                              : false;

    
    this.fileUrl = this.technicianService.service.getSuperAdmin() + '/downloadFile/';
    // console.log('PopoverData: ', this.marginLeft/15.5);

     

  }

  closePopover() {
    this.close.emit();
  }

  leave(event){
    // console.log('leave', true);
    this.close.emit();
  }

  openProfile(code: any){
    if(code){
      if(this.appPopoverData?.popoverShowFor === 'customer'){
        // this.router.navigate(['/dashboard/customer/customer-add'], { queryParams: { patientCode: code } });
        this.technicianService.common.openCustomerDetailPopup(code);
      }
       else
       {
         if(this.loggedInUserType === 'Customer')
         {
           this.technicianService.common.oppUp_StaffShortDetails(code);
         }
         else
         {
          this.technicianService.common.openStaffDetailPopup(code);
         }
        
      }
    }
  }


  
  
}
