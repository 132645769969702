<div class="ssd-container">

    <div class="ssd-header">
    <h6 class="dialog-header ">Staff Details</h6>
    <mat-icon class="arrow_right" (click)="closeDialog()">keyboard_arrow_right</mat-icon>
    </div>

    <div class="ssd-content">

        <div class="flex-col-center">
            <label>{{staffDetails.staffName}}</label>
            <img  class="s-img" [alt]="staffDetails.staffName" [src]="img_serverPath + staffDetails.staffLogo"
                onerror="this.src='assets/common_images/profile.jpg';" />

        </div>

        <div class="flex-col-center">
            <ul class="list">
                <li *ngIf="staffDetails.staffPhone">
                    <div class="ssd-icons">
                        <mat-icon class="phone-icon"> phone</mat-icon>
                        <span> {{staffDetails.staffPhone}}</span>
                    </div>
                </li>
                <li *ngIf="staffDetails.email">
                    <div class="ssd-icons">
                        <mat-icon class="mail-icon"> email</mat-icon>
                        <span>{{staffDetails.email}}</span>
                    </div>
                </li>
            </ul>

        </div>

        <div class="ssd-doc" *ngIf="staffDetails?.workerCertificationResList || staffDetails?.workerLicenceResList">
           <label class="ssd-doc-heading">Uploaded Documents</label>
            <ul class="list mt-2">

             <!-- <ng-container *ngIf="staffDetails.workerCertificationResList.length > 0">
                <label class="ssd-doc-subheading">Certificates:</label>
              <li *ngFor="let doc of staffDetails.workerCertificationResList" style="cursor: pointer;"
              (click)="viewFiles(doc.certificateFileName)">
               <span>{{doc.certificationName}}</span>
              </li>
              </ng-container>

              <ng-container *ngIf="staffDetails.workerLicenceResList.length > 0">
                <label class="ssd-doc-subheading">License:</label>
              <li *ngFor="let doc of staffDetails.workerLicenceResList" style="cursor: pointer;"
              (click)="viewFiles(doc.licenceFileName)">
               <span>{{doc.licenceName}}</span>
              </li>
              </ng-container> -->

              
            <ng-container *ngIf="staffDetails?.workerCertificationResList?.length > 0">
              <label class="ssd-doc-subheading">Certificates:</label>
              <ng-container *ngFor="let doc of staffDetails.workerCertificationResList">
                <div class="d-flex flex-column">
                <span class="ssd-doc-doc-name">{{doc.certificationName}}</span> 
        
                <div *ngIf="doc?.certificateFileName && doc?.certificateFileName?.length > 0">
                  <li *ngFor="let file of doc?.certificateFileName; let i = index" style="cursor: pointer;"
                    (click)="viewFiles(file)">
                    {{i+1+'.'}}&nbsp;<span>{{formateFile(file)}}</span>
                  </li>
                </div>
                 </div>
              </ng-container>
            </ng-container>
        
            <ng-container *ngIf="staffDetails?.workerLicenceResList?.length > 0">
              <label class="ssd-doc-subheading">Licence:</label>
              <ng-container *ngFor="let doc of staffDetails.workerLicenceResList">
                <div class="d-flex flex-column">
                <span class="ssd-doc-doc-name">{{doc.licenceName}}</span> 
                <div *ngIf="doc?.licenceFileName && doc?.licenceFileName?.length > 0">
                  <li *ngFor="let file of doc?.licenceFileName; let i = index" style="cursor: pointer;"
                    (click)="viewFiles(file)">
                    {{i+1+'.'}}&nbsp;<span>{{formateFile(file)}}</span>
                  </li>
                </div>
                </div>
              </ng-container>
            </ng-container>

            </ul>

            <div class="noRecordFound mt-2" *ngIf="staffDetails.workerCertificationResList.length === 0 && staffDetails.workerLicenceResList.length === 0">
              <span>No documents found.</span>
            </div>

            

          </div>

          <div class="col-lg-12 text-center" *ngIf="isLoading">
            <span>
              <span class="spinner-border spinner-border-sm"></span>
              &nbsp; Getting staff details...
            </span>
          </div>

    </div>

   
</div>