import { Component } from '@angular/core';
import { VersionCheckService } from './version-check.service';
//import { environment } from 'src/environments/environment.prod';
import { ErrorLoggingService } from './shared/services/error-logging.service';
import { SessionStorageService } from 'angular-web-storage';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'dashboard';
  constructor(
    public  versionCheckService:VersionCheckService,
    private errorLoggingService: ErrorLoggingService,
    private sessionService: SessionStorageService){}

  ngOnInit(){
    this.setLanguageFile()
    //console.log('I am called');
    //this.simulateError()
    /* if(location.protocol==='https:'){
      this.versionCheckService.initVersionCheck(environment.versionCheckURLSecure);

    }else{
      this.versionCheckService.initVersionCheck(environment.versionCheckURL);

    } */
  }
  simulateError(): void {
    try {
      // Simulate an error
      throw new Error('This is a test error');
    } catch (error) {
      // Log the error using the ErrorLoggingService
      this.errorLoggingService.logError(error);
    }
  }

  setLanguageFile()
  {
    this.sessionService.set('language', 'homehealthcaredashboarden');
  }

}
