<ng-container *ngIf="staffName || customerName">
  
<div>
  <span class="d-inlineFlex" [class.maxWidthHov]="lableTxt" [class.d-flex]="lableTxt" appPopover
  [appPopoverData]="appPopoverData" [placement]="'top'">
  <span *ngIf="isRowNameWithLogo">
    <img [src]="fileUrl + (staffLogo || customerLogo)" class="user-image" [class.headAvatar]="lableTxt" alt="User Image"
      onerror="this.src='assets/common_images/profile.jpg';">
  </span>

  <p *ngIf="lableTxt" class="break-words">
    <small>{{lableTxt}}</small><br>
    <span>{{(staffName || customerName) | capitalize}}</span>
  </p>

  <p *ngIf="!lableTxt" class="break-words adjLineHeight">
    <span>{{(staffName || customerName) | capitalize}}</span>
  </p>

</span>
</div>


<!--   
<ng-template [ngIf]="loggedIn_userType === 'Customer'" [ngIfElse]="DefaultView_Template">
  <span class="d-inlineFlex" [class.maxWidthHov]="lableTxt" [class.d-flex]="lableTxt">
    <span *ngIf="customerViewLimits?.can_view_staff_profile_pic && isRowNameWithLogo">
      <img [src]="fileUrl + (staffLogo || customerLogo)" class="user-image" [class.headAvatar]="lableTxt" alt="User Image"
        onerror="this.src='assets/common_images/profile.jpg';">
    </span>

    <p *ngIf="lableTxt && customerViewLimits?.can_view_staff_name" class="break-words">
      <small>{{lableTxt}}</small><br>
      <span>{{(staffName || customerName) | capitalize}}</span>
    </p>

    <p *ngIf="!lableTxt && customerViewLimits?.can_view_staff_name" class="break-words adjLineHeight">
      <span>{{(staffName || customerName) | capitalize}}</span>
    </p>

  </span>
</ng-template>

<ng-template #DefaultView_Template>
  <span class="d-inlineFlex" [class.maxWidthHov]="lableTxt" [class.d-flex]="lableTxt" appPopover
    [appPopoverData]="appPopoverData" [placement]="'top'">
    <span *ngIf="isRowNameWithLogo">
      <img [src]="fileUrl + (staffLogo || customerLogo)" class="user-image" [class.headAvatar]="lableTxt" alt="User Image"
        onerror="this.src='assets/common_images/profile.jpg';">
    </span>

    <p *ngIf="lableTxt" class="break-words">
      <small>{{lableTxt}}</small><br>
      <span>{{(staffName || customerName) | capitalize}}</span>
    </p>

    <p *ngIf="!lableTxt" class="break-words adjLineHeight">
      <span>{{(staffName || customerName) | capitalize}}</span>
    </p>

  </span>
</ng-template> -->
</ng-container>