<div class="w-100">
  <div class="btn_generate_pdf" *ngIf="dataToPrint.length > 0">
    <button *ngIf="!isGeneratingPDF" type="button"
        mat-raised-button
        color="accent"
        class="col-auto"
        (click)="generatePDF()"
        [disabled]="disablePDF"
      >
      Roster Print
    </button>
    <ng-template [ngIf]="isGeneratingPDF">
      <span class="spinner-border spinner-border-sm"></span> &nbsp;
      <span>Generating PDF...</span>
    </ng-template>
  </div>

  <ng-template [ngIf]="rosterPrintData" [ngIfElse]="else_temp">

    <div class="row">


      <div class="col-1">
        <div class="row">
          <div class="card-jobdate" [style.color]="rosterPrintData?.jobTypeColor">
            <span>{{rosterPrintData?.week_day}}</span>
            <span class="month-day">{{rosterPrintData?.month_day}}</span>
            <span>{{rosterPrintData?.month_name}}</span>
          </div>
        </div>
      </div>


      <div class="col-3">
        <small>Customer Name </small><br>
        <p>
          <span>
            <profile-avatar-with-name [userDetail]="rosterPrintData" [isRowNameWithLogo]="false" [popoverShowFor]="'customer'" [isProfileButtonEnable]="true" [isChatButtonEnable]="false"></profile-avatar-with-name>
         </span>
        </p>
      </div>


      <div class="col-2">
        <small>{{ 'main.labels.Job Site' | translate }} </small><br>
        <p> {{rosterPrintData.siteName}}</p>
      </div>

      <div class="col-2">
        <small> Shift</small><br>
        <p> {{rosterPrintData.startTime}}</p>
      </div>

      <div *ngIf="rosterPrintData?.clockinPin" class="col-4 d-flex">
        <div>
          <small> Clock-In PIN</small><br>
          <p class="clock_in_pin"> {{rosterPrintData.clockinPin ? rosterPrintData.clockinPin : 'NA'}}</p>
        </div>

        <ng-template [ngIf]="rosterPrintData.clockinPin">
          <div class="qr-pos">
            <qrcode #qrCodeElement [qrdata]="rosterPrintData.clockinPin.toString()" [width]="100"
              [errorCorrectionLevel]="'M'" [elementType]="'canvas'">
            </qrcode>
          </div>
        </ng-template>

      </div>

    </div>

    <div class="col-md-12 shift-roster-table">
     <table class="table table-bordered">
        <thead>
          <tr>
             <th scope="col">Name</th>
            <th scope="col">Role</th>
            <!-- <th scope="col">Clock-in Time</th>
            <th scope="col">Clock-out Time</th>
            <th scope="col">Comments</th> -->
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let parent_element of rosterPrintData.jobTypeShiftDetailsList">
            <ng-container *ngFor="let child_element of parent_element.staffList">
              <tr>
                <td>{{child_element.workerName}} </td>
                <td>{{parent_element.jobTypeName}} </td>
                <!-- <td> </td>
                <td> </td>
                <td> </td> -->
              </tr>
            </ng-container>
          </ng-container>

        </tbody>
      </table>

    </div>

  </ng-template>


  <ng-template #else_temp>
    <div class="row d-flex align-items-center spinner" *ngIf="isLoading">
      <div class="col-lg-1 text-end">
        <mat-spinner></mat-spinner>
      </div>
      <div class="col-lg-8 text-start"><span>Please Wait ...</span></div>
    </div>

    <div class="row" *ngIf="!isLoading && !rosterPrintData">
      <div>
        <span class="text-danger">No Record Available</span>
      </div>
    </div>
  </ng-template>


</div>