
export enum settings_keys 
{
        CALENDAR_VIEW = "Calendar.View",
        START_OF_WEEKS = "Start.Of.Week",
        RADIUS_UNIT = "Radius.Unit",
        INVOICE_NOTE = "Invoice.Note"
         
}

export enum SETTING_TYPES 
{ 
        INVOICE_SETTING = "InvoiceSetting"
}
