  <div class="row mb-3">
    <div class="col-md-6 align-self-center">
      <h6 class="mb-0 dialog-header">{{ popupTitle | capitalize }}</h6>
    </div>
    <div class="col-md-6 text-end">
      <button class="btn-close cursor-pointer" [mat-dialog-close]="true"></button>
    </div>
  </div>

  <div class="row">

    <div class="w-100 mb-3">

      <div class="popup-viewport-body" *ngIf="notifyUsersList.length > 0" clickOutside (clickOutside)="showPopup = ''">

        <!-- <div *ngFor="let user of notifyUsersList | searchFilter : searchText:['name', 'email']; let idx = index" class="example-item"> -->

          <div *ngFor="let user of notifyUsersList ; let idx = index" class="example-item">
          <mat-card class="card-appointment mt-2 mb-2" *ngIf="user.workerName && user.email">

            <div class="row g-2 icon-round">
              
              <!-- <div  class="col w_68px" *ngIf="!user?.workerLogo">
                <img class="avatarImg" [src]="'assets/common_images/profile.jpg'">
              </div>
              <div class="col w_68px" *ngIf="user?.workerLogo">
                <div class="avatarImg" [style.background-image]="'url(' + dashboarService.service.getSuperAdmin() + '/downloadFile/' + user.workerLogo + ')'">
                </div>
              </div> -->

              <!-- <div class="col w_68px" *canCustomerView="'profile_pic'"> -->
                <div class="col-6">
                <div class="row"> 
                  <div class="col-2 pe-0" *canCustomerView="'profile_pic'">
                    <img class="avatarImg" loading="lazy" [alt]="user?.workerLogo ?user.workerLogo : 'worker-profile-pic'"
                      [src]="dashboarService.service.getSuperAdmin() + '/downloadFile/' + user.workerLogo "
                      onerror="this.src='assets/common_images/profile.jpg';" />
                  </div>
                
                  <div class="col w-auto">
                
                    <!-- <div *ngIf="userType !== 'Customer'">
                      <span class="cursor-pointer pointer">
                        <span><profile-avatar-with-name [userDetail]="user" [isRowNameWithLogo]="false" [popoverShowFor]="'staff'" [isProfileButtonEnable]="true" [isChatButtonEnable]="true"></profile-avatar-with-name></span>
                      </span>
                    </div>
                
                    <div *ngIf="userType === 'Customer'">
                      <span class="cursor-pointer pointer">
                        <span>{{user.workerName}} </span>
                      </span>
                    </div> -->
                
                    <div>
                      <span class="cursor-pointer pointer">
                        <span><profile-avatar-with-name [userDetail]="user" [isRowNameWithLogo]="false" [popoverShowFor]="'staff'" [isProfileButtonEnable]="true" [isChatButtonEnable]="true"></profile-avatar-with-name></span>
                      </span>
                    </div>
                    <small *canCustomerView="'email'">{{user.email}}</small>
                  </div>

                </div>
                
                </div>

               <div class="col-3">
                <div class="label-value">
                  <span class="label"> Notified At</span>
                  <small class="value">{{user.notifiedAt}}</small>
                </div>
                 </div>

                 <div class="col-2">
                  <div class="label-value">
                    <span class="label">Status</span>
                    <small class="value" [ngClass]="user?.badgeColor">{{user.notifyStatus}}</small>
                  </div>
                   </div>

                   <div class="col-1 d-flex align-items-center justify-content-center"
                    *ngIf="user?.notifyStatus && user.notifyStatus.toLowerCase() === 'pending'"
                    >
                    <img
                    style="cursor: pointer;"
                    loading="lazy"
                    matTooltip="Re-Notify" matTooltipPosition="above"
                    src="../../../../assets/images/renotify-icon.svg"
                    (click)="reNotifyStaff(user)"
                    >
                    </div>


                    <!-- <mat-icon
                     matTooltip="Re-Notify" matTooltipPosition="above"
                     (click)="reNotifyStaff(user)"
                     >
                     notifications_active</mat-icon>
                   </div> -->

            </div>

          </mat-card>
        </div>
      </div>

      <div class="row d-flex align-items-center" *ngIf="showSpinner && notifyUsersList?.length === 0">
        <div class="col-lg-2 text-end spinner">
          <mat-spinner></mat-spinner>
        </div>
        <div class="col-lg-8 text-start"><span>Please Wait, Getting notified staff ...</span></div>
      </div>

      <div *ngIf="notifyUsersList?.length === 0 && !showSpinner" class="mt-4 text-danger">
        No notified staff found
      </div>

    </div>
  </div>