import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ApiRootService } from 'src/app/shared/api-root.service';
import { CommonService } from 'src/app/shared/common.service';
import { SessionStorageService } from 'angular-web-storage';
import { CryptoService } from 'src/app/shared/services/crypto.service';
import { PreferenceSettingService } from 'src/app/dashboards/technician-dashboard/preference-setting/preference-setting.service';
import { TechnicianService } from 'src/app/dashboards/providers-dashboard/technician/technician.service';
import { Router } from '@angular/router';
import { IPagePermissionResponse } from 'src/app/shared/interface/login.interface';
import { AuthService } from 'src/app/shared/auth.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  headers;
  healthProviderOwner = 'Owner';
  healthProviderAdmin = 'Admin';
  healthTechnician = 'Staff';
  customer = "Customer";
  token;

  constructor(private http: HttpClient, private service: ApiRootService, public common: CommonService, private localStorage: SessionStorageService, private _cryptoService: CryptoService, private _preferenceSettingSVC : PreferenceSettingService, private _technicianSVC: TechnicianService, private router: Router, private auth: AuthService) { }

  authenticateUser(formData, callback) {
    this.headers = new HttpHeaders({ 'Content-Type': 'application/json', 'platform': 'web', 'Access-Token': ['b8fa3263-4a90-41e0-a5e8-896667268b62'] });
    return this.http.post(this.service.getBaseUrl() + '/auth', formData, { headers: this.headers, observe: 'response' }).subscribe(
      (data) => {
        callback({ responsecode: 200, token: data.headers.get('authorization'), data });
      },
      (error) => {
        callback({ responsecode: 401, message: "User Name or Password Incorrect" })
      }
    )
  }

  getUserType(username, callback) {
    return this.http.get(this.service.getRegistrationUrl() + '/workerType/').subscribe(
      (data) => {
        //////console.log(data);
        callback(data);
      }
    )
  }

  checkOrganizationSetting(orgcode, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/checkOrganizationSetting/' + orgcode).subscribe((data) => {
      callback(data);
    })
  }

  getUserByEmailAndOrganizationCode(email, orgCode, callback) {
    return this.http.get(this.service.getRegistrationUrl() + '/getUserByEmailAndOrganizationCode/' + email + '/' + orgCode).subscribe((data) => {
      callback(data);
    })
  }

  autoLogin(formData, callback) {

    this.headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Token': ['b8fa3263-4a90-41e0-a5e8-896667268b62'] });
    return this.http.post(this.service.getRegistrationUrl() + '/autoLogin', formData, { headers: this.headers, observe: 'response' }).subscribe(
      (data) => {
        callback({ responsecode: 200, token: data.headers.get('authorization'), data });
      },
      (error) => {
        callback({ responsecode: 401, message: "User Name or Password Incorrect" })
      }
    )

  }
  getOrganizationOnboardingByOrganizationCode(code,callback){
    return this.http.get(this.service.getRegistrationUrl()+'/getOrganizationOnboardingByOrganizationCode/'+code).subscribe((data)=>{
      callback(data);
    })
  }

  getAllPagePermissionDetailsByUserCode(userCode, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/getAllPagePermissionDetailsByUserCode/' + userCode).subscribe((data)=>{
      callback(data);
    });
  }

  onOrganizationSelect(organizationCode: any, email: any, isReferrerURL = null){
    // console.log('OnOrganizationSelect_call_Login_Services: ', organizationCode, email,isReferrerURL);
    this.common.progressLoader = true;
    // console.log('SelectedOrg: ', organizationCode);
    if(email && organizationCode){
      // console.log('selectedOrgCode: ', email, organizationCode);
      this.getUserByEmailAndOrganizationCode(email, organizationCode, (response) => {
        this.common.progressLoader = false;
        if (response.responsecode == 200) {
          // console.log('Res: ', response);
          // Transforming the data
            const transformedData = {
              ...response,
              data: {
                  body: {
                      data: response.data
                  }
              }
            };
          this.getLoggedInData(transformedData, null, isReferrerURL);
        } else {
          // Do Nothing...
        }
      });
    }
  }

  getLoggedInData(response, isCOmmon = null, isReferrerURL = null) {
    if (response.responsecode == 200 && (response.data.body.data.isVerified || isCOmmon)) {
      this.token = response?.token ? response.token.split(' ')[1] : this.localStorage.get('token');
      // //////console.log(this.token);
      this.localStorage.set('token', this.token);      

      if (this.token) {
        this.localStorage.set('loggedUserData', response.data.body.data);
        this.localStorage.set('username', response.data.body.data.name);
        // const type_Data = response.data.body.data.roleName;
        // this.localStorage.set('user_type', type_Data);
        this.localStorage.set('userCode', response.data.body.data.userCode);
        this.localStorage.set('email', response.data.body.data.email);
        this.localStorage.set('image', response.data.body.data.userImage);
        this.localStorage.set('roleCode', response.data.body.data.roleCod);
        this.localStorage.set('log_type', response.data.body.data.userType);
        if (response.data.body.data.organizationList.length > 0) {

          this.localStorage.set('timeZone', response.data.body.data.organizationList[0].organizationTimeZone);
          this.localStorage.set('orgCode', response.data.body.data.organizationList[0].organizationCode);
          this.localStorage.set('orgName', response.data.body.data.organizationList[0].organizationName);
          this.localStorage.set('org_list', response.data.body.data.organizationList);
          this.localStorage.set('selected_OrgCode', response.data.body.data.organizationList[0].organizationCode);
          this.localStorage.set('orgStatus', response.data.body.data.organizationList[0].organizationStatus);
          this.localStorage.set('docExpiry', response.data.body.data.organizationList[0].isDocExpired);
          this.localStorage.set('isActiveOrg', response.data.body.data.organizationList[0].isActiveOrg);
          this.common.organizationStatus = response.data.body.data.organizationList[0].organizationStatus;
          // //console.log(this.common.organizationStatus);
        } else {
          this.localStorage.set('org_list', null);
          this.localStorage.set('selected_OrgCode', null);
          this.localStorage.set('timeZone', null);
          this.localStorage.set('orgCode', null);
          this.localStorage.set('orgName', null);
          this.localStorage.set('orgStatus', null);
        }

        if (this.token) {
          if (response?.data?.body?.data?.userCode) {
            this.getAllPagePermissionDetailsByUserCode(response.data.body.data.userCode, (responseData: IPagePermissionResponse) => {
              // console.log('AllPagePermissionDetailsByUserCode: ', responseData);
              this.common.progressLoader = false;
              if (responseData.responsecode === 200) {
                if (responseData?.data) 
                  {
                  this.localStorage.set("super_admin_code", responseData.data.adminCode);
               //   console.log("responseData.data.adminCode", responseData.data.adminCode);

                  this.localStorage.set('MenuListDataAndPagePermission', responseData.data);
                  if (responseData?.data?.role) {
                    this.localStorage.set('user_type', responseData.data.role);
                    const type_Data = responseData.data.role;
                    this.localStorage.set('user_type', type_Data);
                    // //console.log('LoggedUserRole_type_Data: ', type_Data);
                    this.common.globalImage = response.data.body.data.userImage;
                    this.common.islogin = true;

                    if (type_Data === 'Staff') {
                      this.common.getGlobalCountChat();
                      this.common.getAllChatGroupGlobalChat();
                      // if(response?.data?.body?.data?.organizationList[0]?.organizationCode){
                      //   this.getGlobalLeaveCount(response.data.body.data.organizationList[0].organizationCode);
                      // }

                      this.getStaffPreferencesSettings(isReferrerURL);

                      // this.common.progressLoader = false;
                      // this.common.islogin = false;
                      // this.router.navigate(['/dashboard-technician']);
                    }

                    else if (type_Data === 'Customer') 
                    {
                      if (response?.data?.body?.data?.organizationList[0]?.organizationCode) 
                      {
                        this.getLoginCustomerCode(response.data.body.data.userCode);
                      }
                      this.common.progressLoader = false;
                      this.common.islogin = false;
                      this.checkPermission(responseData, '/dashboard-customer', isReferrerURL);
                      //this.router.navigate(['/dashboard-customer']);
                    }

                    // if(type_Data !== 'Staff' && type_Data !== 'Customer'){
                    else {
                      this.common.getGlobalCountChat();
                      this.common.getAllChatGroupGlobalChat();
                      // if(response?.data?.body?.data?.organizationList[0]?.organizationCode){
                      //   this.getGlobalLeaveCount(response.data.body.data.organizationList[0].organizationCode);
                      // }
                      this.common.progressLoader = false;
                      this.common.islogin = false;

                      this.checkPermission(responseData, '/dashboard', isReferrerURL);

                      // if (responseData.data?.ppLst.length > 0) {
                      //   const index = responseData.data?.ppLst.findIndex(ele => ele.pagePermissionName === 'Dashboard');

                      //   if (index !== -1) {
                      //     this.router.navigate(['/dashboard']);
                      //   }
                      //   else {
                      //     let childList: any[] = responseData.data?.ppLst[0].childList || [];

                      //     if (childList.length > 0) {
                      //       this.router.navigateByUrl(childList[0].pageUrl);
                      //     }
                      //     else {
                      //       this.router.navigateByUrl(responseData.data?.ppLst[0].pageUrl);
                      //     }
                      //   }

                      // }
                      // else {
                      //   this.common.loginmessage = "User do no have any page permission to view";
                      //   this.common.loginerrorMessage = true;
                      // }

                    }

                    this.auth.login();

                   // this._validateRouteSVC.setValidRoutes();

                    if (response?.data?.body?.data?.roleCod) {
                      // //console.log('Logged User Role is: ', response.data.body.data.roleCod);
                    } else {
                      this.common.progressLoader = false;
                      this.common.loginmessage = 'Logged User Role is not defined please try with another user';
                      this.common.loginerrorMessage = true;
                    }
                  }
                  this.localStorage.set("p_list", this._cryptoService.performeEncryption(responseData.data.ppLst));
                }
              } 
              else
               {
                this.localStorage.set('MenuListDataAndPagePermission', null);
                this.common.progressLoader = false;
                this.common.loginmessage = responseData.message;
                this.common.loginerrorMessage = true;
              }
            });
          }
        }

      }
    } else if (response.responsecode == 200 && !response.data.body.data.isVerified && !isCOmmon) {
      this.common.progressLoader = false;
      this.common.loginmessage = 'Your account is not activated please do check your email for activation link.';
      this.common.loginerrorMessage = true;
    } else {
      this.common.progressLoader = false;
      this.common.loginmessage = response.message;
      this.common.loginerrorMessage = true;

    }
  }

  checkPermission(responseData:any, route:string, isReferrerURL = null)
  {
    if (responseData.data?.ppLst.length > 0) {
      const index = responseData.data?.ppLst.findIndex(ele => ele.pagePermissionName === 'Dashboard');

      if (index !== -1) {
        if(isReferrerURL){                
          this.goToLink(isReferrerURL);
        } else {
          this.router.navigateByUrl(route);
        }
      }
      else {
        let childList: any[] = responseData.data?.ppLst[0].childList || [];

        if (childList.length > 0) {
          if(isReferrerURL){                
            this.goToLink(isReferrerURL);
          } else {
            this.router.navigateByUrl(childList[0].pageUrl);
          }
        }
        else {
          if(isReferrerURL){                
            this.goToLink(isReferrerURL);
          } else {
            this.router.navigateByUrl(responseData.data?.ppLst[0].pageUrl);
          }          
        }
      }

    }
    else {
      this.common.loginmessage = "User do no have any page permission to view";
      this.common.loginerrorMessage = true;
    }
  }

  getStaffPreferencesSettings(isReferrerURL = null)
  {
    this._preferenceSettingSVC.getworkerHoursOfOperationByworkerCode(this.localStorage.get('userCode'), (outerAPI_Res:any) => {

      this._preferenceSettingSVC.getWorkerAvailabilityExtraWorkByWorkerCode(this.localStorage.get('userCode'), (innerAPI_Res:any) => {
        this.common.progressLoader = false;
        this.common.islogin = false;
        if(outerAPI_Res.responsecode === 200 || innerAPI_Res.responsecode === 200)
        {

           this._technicianSVC.getWorkerProfilePersonalTabforMobileByworkerCode(this.localStorage.get('userCode'), this.common.local.get('orgCode'), (response) => {
            
            if(response?.data?.address)
            {
              // if we g0t address then that case not need to allow staff to update their profile
              // then directly redirecting to staff dashboard
              if(isReferrerURL){                
                this.goToLink(isReferrerURL);
              } else {
              this.router.navigate(['/dashboard-technician']);
              }
            }
            else{
               
              // else allow staff to update their profile
              this.router.navigate(['/dashboard-technician/self/update'], { queryParams: {workerCode:this.localStorage.get('userCode')}});
            }

            })

            
        }
        else{
          // allowing to update user prefrence just after login
          this.router.navigateByUrl("/dashboard-technician/preference-setting");
        }

      });

     });
  }

  getLoginCustomerCode(userCode: string) {
    this.common.getLoginCustomerCode(userCode).subscribe((data: any) => {
      if (data.responsecode == 200) {
        this.localStorage.set('logedIn_CustomerCode', data.data.customerDetail.customerCode);
      }

    })
  }

  goToLink(link) {
    this.router.navigate([`${link.split('?')[0]}`], { queryParams: this.sortParams(link) });
  }

  sortParams(link) {  
    if(link.split('?')[1]){
    let queryParams = link.split('?')[1];
    let params = queryParams.split('&');
    let pair = null;
    let data = {};
    params.forEach((d) => {
      pair = d.split('=');
      data[`${pair[0]}`] = pair[1];
    });
    return data;
    }
  }

}
