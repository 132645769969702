import { Component, OnInit, Input} from '@angular/core';
import { TechnicianService } from '../../dashboards/providers-dashboard/technician/technician.service';
import { CommonService } from 'src/app/shared/common.service';
import { ICustomerViewLimitations } from 'src/app/shared/interface/ICustomerViewLimits';

@Component({
  selector: 'profile-avatar-with-name',
  templateUrl: './profile-avatar-with-name.component.html',
  styleUrls: ['./profile-avatar-with-name.component.scss']
})
export class ProfileAvatarWithName implements OnInit {
  fileUrl: any;
  appPopoverData: any;
  @Input() lableTxt: any;
  @Input() userLogo: any;
  @Input() userName: any;
  @Input() userDetail: any;
  @Input() isProfileButtonEnable: boolean = false;
  @Input() isChatButtonEnable: boolean = false;
  @Input() popoverShowFor: any;
  @Input() isRowNameWithLogo: boolean = false;
  @Input() loggedIn_userType : string = '' ;
  staffName: any;
  staffCode: any;
  staffLogo: any;
  customerName: any;
  customerCode: any;
  customerLogo: any;


  customerViewLimits : ICustomerViewLimitations;

  constructor(
    public technicianService: TechnicianService,
    private commonService : CommonService) {}

  ngOnInit() {
    // console.log('userDetail: ', this.userDetail);
    // console.log('isProfileButtonEnable: ', this.isProfileButtonEnable);
    // console.log('isChatButtonEnable: ', this.isChatButtonEnable);
    // console.log('popoverShowFor: ', this.popoverShowFor);
    this.fileUrl = this.technicianService.service.getSuperAdmin() + '/downloadFile/';

    this.staffName = this.popoverShowFor === 'staff' && (this.userDetail?.staffName || this.userDetail?.workerName || this.userDetail?.userName || this.userDetail?.name);
    this.staffCode = this.popoverShowFor === 'staff' && (this.userDetail?.staffCode || this.userDetail?.workerCode || this.userDetail?.userCode || this.userDetail?.code);
    this.staffLogo = this.popoverShowFor === 'staff' && (this.userDetail?.staffLogo || this.userDetail?.workerLogo || this.userDetail?.userLogo || this.userDetail?.logo || this.userDetail?.photo);

    this.customerName = this.popoverShowFor === 'customer' && (this.userDetail?.customerName || this.userDetail?.patientName);
    this.customerCode = this.popoverShowFor === 'customer' && (this.userDetail?.customerCode || this.userDetail?.patientCode);
    this.customerLogo = this.popoverShowFor === 'customer' && this.userDetail?.customerLogo;  

    let appPopoverData = {
      staffName: this.staffName,
      staffCode: this.staffCode,
      staffLogo: this.staffLogo,
      customerName: this.customerName,
      customerCode: this.customerCode,
      customerLogo: this.customerLogo,
      userDetail: this.userDetail,
      lableTxt: this.lableTxt,
      isProfileButtonEnable: this.isProfileButtonEnable === true ? true : this.isProfileButtonEnable === undefined ? false : false,
      isChatButtonEnable: this.isChatButtonEnable === true ? true : this.isChatButtonEnable === undefined ? false : false,
      popoverShowFor: this.popoverShowFor,
      isRowNameWithLogo: this.isRowNameWithLogo
    };
    this.appPopoverData = appPopoverData;
    // console.log('appPopoverData: ', appPopoverData); 
  this.subscribeToCustomerViewLimit();

  }

  subscribeToCustomerViewLimit()
  {
    this.commonService.getCustomerViewLimits().subscribe((res:ICustomerViewLimitations)=>{

      if(res)
      {
        this.customerViewLimits = res ;
      }

    });
  }
  
}

