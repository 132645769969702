<div class="text-center w-100 call">
    <i class="material-icons call-icon pcallicon"> call </i>
    <button type="button" class="close float-end" aria-label="Close" (click)="onNoClick()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>




<div *ngIf="patientList" class="details">
    
    <div class="p-2 mx-auto w-50">
        <mat-card class="example-card text-center">
            <div class="techd pt-2 text-center"><h6 class="callt">CALL CLINICIAN</h6></div>
            <div class=" pt-2 ">
                <span>{{patientList.technicianName}}</span>
            </div>
            <div class="pt-2">
                <p *ngIf="patientList.technicianWorkPhone" class="pnumber pt-1">{{patientList.technicianWorkPhone}}</p>
                <p *ngIf="patientList.homeNumber" class="pnumber ">{{patientList.homeNumber}}</p>
            </div> 
        </mat-card>
    </div>
    <hr>
    <div class="row scroll-p" *ngIf="patientList.appointmentsDeatilsList.length > 1">
            <div  class="col-md-6 mb-2 ps-3 pe-3 pt-2" *ngFor="let patient of patientList.appointmentsDeatilsList">
                <mat-card class="example-card text-center">
                    <div class="techd pt-2 text-center"><h6 class="callp">CALL PATIENT</h6></div>
                    <div class=" pt-2 ">
                        <span><profile-avatar-with-name [userDetail]="patient" [isRowNameWithLogo]="false" [popoverShowFor]="'customer'" [isProfileButtonEnable]="true" [isChatButtonEnable]="false"></profile-avatar-with-name></span>
                    </div>
                    <div class="pt-2">
                        <p *ngIf="patient.patientPhone" class="pnumber pt-1">{{patient.patientPhone}}</p>
                    </div> 
                    <mat-card-actions class="p-0 w-100 text-center">
                        <div *ngIf="patient && patient.appointmentDurationCode"
                            (click)="assignAppointment(patient.appointmentDurationCode)">
                            <img src="/assets/reassign.png" class="cursor-pointer" title="Change Clinician">
                        </div>
                    </mat-card-actions>
    
                </mat-card>
            </div> 
    </div>
    <span class="w-100" *ngIf="patientList.appointmentsDeatilsList.length === 1">  
        <div  class="w-50 mx-auto mb-2 ps-3 pe-3 pt-2" *ngFor="let patient of patientList.appointmentsDeatilsList">
            <mat-card class="example-card text-center">
                <div class="techd pt-2 text-center"><h6 class="callp">CALL PATIENT</h6></div>
                <div class=" pt-2 ">
                    <span><profile-avatar-with-name [userDetail]="patient" [isRowNameWithLogo]="false" [popoverShowFor]="'customer'" [isProfileButtonEnable]="true" [isChatButtonEnable]="false"></profile-avatar-with-name></span>
                </div>
                <div class="pt-2">
                    <p *ngIf="patient.patientPhone" class="pnumber pt-1">{{patient.patientPhone}}</p>
                </div> 
                <mat-card-actions class="p-0 w-100 text-center">
                    <div *ngIf="patient && patient.appointmentDurationCode"
                        (click)="assignAppointment(patient.appointmentDurationCode)">
                        <img src="/assets/reassign.png" class="cursor-pointer" title="Change Clinician">
                    </div>
                </mat-card-actions>

            </mat-card>
        </div>
    </span>
</div>