import { Component, OnInit, ViewChild, OnDestroy, TemplateRef, Input, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl, FormBuilder, FormArray, FormControlName } from '@angular/forms';
import { UserRegistrationService } from '../user-registration.service';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmLogoutComponent } from 'src/app/common/confirm-logout/confirm-logout.component';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Observable } from 'rxjs';

interface IcustomerDataPermissionRequestList
{
  dataPermissionCode: string,
  dataPermissionName: string,
  customerAdminCode: string,
  isView: boolean
} 
 

@Component({
  selector: 'add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {
  isShortcutModalOpen: boolean = false;
  @Input() customerCode: any;
  @Input() isCustomerLoggedIn: boolean = false;
  addAdminForm: FormGroup;
  selectedCustomerCode: any;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  customerAdminCode: any;
  customerData: any;
  submitted: true;
  customerList:any =[];
  //displayedColumns = ['adminName', 'roleName', 'phoneNumber', 'adminEmail', 'edit'];
  dataSourceUser = new MatTableDataSource();
  tabAtciveFor: string = 'Customer Detail';

  popupTitle: string = 'Add Contact';

  userRoleList = [];
  allRoleList = [];
  customerAddressList: any;
  dropdownSettings = {};
  dropdownDataList = [];
  selectedDropdownItems = [];
  isDropdownLoaded:boolean = false;

  cancelPatient = false;
  customerPermission:IcustomerDataPermissionRequestList[] = [] ;

  constructor(public dialogRef: MatDialogRef<AddUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public service: UserRegistrationService, public router: Router, public route: ActivatedRoute, public translate: TranslateService,
    private dialog: MatDialog, private fb: FormBuilder
  ) {
    this.translate.setDefaultLang(this.service.local.get('language'));
    this.service.common.sub = this.service.common.confirmEmit.subscribe(
      (data: any) => {
        // //////console.log(data);
        if (data == 'showpopup') {
          this.service.common.showConfirmPopup();
        }
      });

      this.addAdminForm = this.fb.group({
        'customer': new FormControl(null),
        'adminEmail': new FormControl('', [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$")]),
        'adminName': new FormControl('', Validators.required),
        'customerAdminCode': new FormControl(null),
        'customerCode': new FormControl(this.customerCode),
        'organizationCode': new FormControl(this.service.local.get('orgCode')),
        'phoneNumber': new FormControl('', Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")),
        // 'roleName': new FormControl('', Validators.required),
        'customerAdminAddressMappingRequestList': new FormControl(''),
      });
  }

  ngOnInit() 
  {
   if(this.data?.isShortcutModalOpen && this.data?.dataForAdd){
      this.getAllCustomerList();      
    } else {
      this.isShortcutModalOpen = false;
    }

    if(this.customerCode || this.data.customerCode) {
      this.customerCode = this.customerCode || this.data.customerCode;
      this.isShortcutModalOpen = this.data.isShortcutModalOpen;
      // console.log('CustomerCode_Ini:', this.customerCode);
      this.getAllCustomerAddressList(this.customerCode);
    }

    if(this.data && this.data?.dataForAdd){
      this.addEditAdmin(this.data);
    } else {
      this.addEditAdmin(this.data);
    }

    // this.addEditAdmin(null);

    this.selectedDropdownItems = [];
    this.dropdownSettings = {
      singleSelection: false,
      text: 'Select Address',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: 'customClassAng2Multiselect',
      // badgeShowLimit: 5,
      maxHeight:200,
      autoPosition: true
    };
  }

  getAllCustomerList(){
    this.service.common.progressLoader = true;
    this.service.getAllCustomerDetails(this.service.local.get('orgCode')).subscribe((res:any) => {       
     if(res.responsecode == 200){
        this.customerList = res.data ;
        this.service.common.progressLoader = false;
        this.isShortcutModalOpen = this.data.isShortcutModalOpen;
     }
   })
   }

   changeCustomer(event: any){
    //console.log('changeCustomer: ', event.value);
    if(event?.value){
      this.service.common.progressLoader = true;
      this.getAllCustomerAddressList(event.value);
      this.selectedCustomerCode = event.value;
      this.customerCode = event.value;
    }
   }

  getAllCustomerAddressList(customerCode: any){
    this.service.getCustomerDetailByCustomerCode(customerCode, (response) => {
      // console.log('CustomerCode_Res:', this.customerCode, response);
      if (response.responsecode == 200) {
        this.service.customerData = response.data;
        this.customerData = response;
        let customerAddressList = this.customerData?.data && JSON.parse(JSON.stringify(this.customerData.data?.customerAddressResponseList)).map((item, i: { customerAddressCode: any, location: any, id: any }) => {
          return {id: i,  itemName: item.location, customerAddressCode: item.customerAddressCode, customerAdminCode: item.customerAddressCode}
        });
        this.customerAddressList = customerAddressList;
        // console.log('CustomerAddressList: ', this.customerAddressList);
        this.getAdminListByCustomerCode(customerCode);
        this.getAllRoleName();        
        if(this.data?.customerAdminAddressMappingResponseList && this.data?.customerAdminAddressMappingResponseList?.length > 0 && this.customerAddressList && this.customerAddressList?.length > 0){
          this.selectedDropdownItems = this.customerAddressList?.filter((o1:any) => this.data.customerAdminAddressMappingResponseList?.some((o2:any) => o1?.customerAddressCode === o2?.customerAddressCode));
          if(this.selectedDropdownItems && this.selectedDropdownItems?.length > 0){
          this.addAdminForm.controls['customerAdminAddressMappingRequestList'].setValue(this.selectedDropdownItems);          
          this.isDropdownLoaded = true;
          } else {
            this.selectedDropdownItems = [];
            this.addAdminForm.controls['customerAdminAddressMappingRequestList'].setValue([]);
            this.isDropdownLoaded = true;
          }
        } else {
          this.isDropdownLoaded = true;
        }
      }
    })
  }

  getAllRoleName(){
    this.service.getAllRoleName().subscribe((response: any) => {
      if (response.responsecode == 200) {
        this.allRoleList = response.data;
      } else {
        this.allRoleList = [];
      }
    })    
  }

  getAdminListByCustomerCode(customerCode: any){
    // //console.log('customerCode: ', customerCode);
    if(customerCode){
    this.service.common.progressLoader = true;
    this.service.getCustomerAdminDetailsByCustomerCode(customerCode, (response) => {
      if (response.responsecode == 200) {
        this.service.common.progressLoader = false;
        this.userRoleList = response.data;
        this.dataSourceUser = new MatTableDataSource(this.userRoleList);
        // Apply the sorting accessor for case-insensitive sorting
        this.dataSourceUser.sortingDataAccessor = this.service.common.globalSortingDataAccessorForMatSort;
      this.dataSourceUser.sort = this.sort;
      // //console.log('DataSourceUser_1: ', this.userRoleList);
          } else {
            this.service.common.progressLoader = false;
            let dataUserStatic = [];
            this.userRoleList = [];
            this.dataSourceUser = new MatTableDataSource(dataUserStatic);
            // Apply the sorting accessor for case-insensitive sorting
        this.dataSourceUser.sortingDataAccessor = this.service.common.globalSortingDataAccessorForMatSort;
      this.dataSourceUser.sort = this.sort;
      this.userRoleList = [];
      //console.log('DataSourceUser_2: ', this.userRoleList);
          }
      });
    }
  }  

  addEditAdmin(data: any){  
    this.service.customerData = null;
    if(data && !data?.dataForAdd){
      this.popupTitle = 'Update Contact';
      this.service.customerData = data;
      this.customerAdminCode = data.customerAdminCode;
      this.addAdminForm.controls['customerAdminCode'].setValue(data.customerAdminCode);
      this.addAdminForm.controls['adminName'].setValue(data.adminName);
      this.addAdminForm.controls['adminEmail'].setValue(data.adminEmail);
      this.addAdminForm.controls['phoneNumber'].setValue(data.phoneNumber);
      // this.addAdminForm.controls['roleName'].setValue(data.roleName);

      if(data?.customerDataPermissionRequestList.length > 0)
      {
         this.customerPermission = data.customerDataPermissionRequestList;
      }

    } 
    else
     {    
      this.resetUserFormData();  
      this.addAdminForm.controls['customerAdminCode'].setValue(null);
      this.popupTitle = 'Add Contact';
      this.getCust_PageAccessPermission();
      if(!data.customerAdminCode){
        this.isDropdownLoaded = true;
      }
    }
    // //console.log('CustomerAddressList Data: ', this.customerAddressList);
  }

  onItemSelect(item: any) {
    // //console.log('onItemSelect', item);
  }
  OnItemDeSelect(item: any) {
    // //console.log('OnItemDeSelect', item);
  }

  onSelectAll(items: any) {
    // //console.log('onSelectAll', items);
  }
  onDeSelectAll(items: any) {
    // //console.log('onDeSelectAll', items);
  }

  resetUserFormData() {
    if(this.isShortcutModalOpen){
      this.addAdminForm.controls['customer'].setValidators([Validators.required]);
    } else {
      this.addAdminForm.controls['customer'].setValidators(null);
    }
    if(this.selectedCustomerCode){
      this.selectedDropdownItems = [];
    this.addAdminForm = this.fb.group({
      'customer': new FormControl(this.selectedCustomerCode),
      'adminEmail': new FormControl('', [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$")]),
      'adminName': new FormControl('', Validators.required),
      'customerAdminCode': new FormControl(null),
      'customerCode': new FormControl(this.customerCode),
      'organizationCode': new FormControl(this.service.local.get('orgCode')),
      'phoneNumber': new FormControl('', Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")),
      // 'roleName': new FormControl('', Validators.required),
      'customerAdminAddressMappingRequestList': new FormControl(''),
    });
    } else {
    this.selectedDropdownItems = [];
    this.addAdminForm = this.fb.group({
      'customer': new FormControl(null),
      'adminEmail': new FormControl('', [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$")]),
      'adminName': new FormControl('', Validators.required),
      'customerAdminCode': new FormControl(null),
      'customerCode': new FormControl(this.customerCode),
      'organizationCode': new FormControl(this.service.local.get('orgCode')),
      'phoneNumber': new FormControl('', Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")),
      // 'roleName': new FormControl('', Validators.required),
      'customerAdminAddressMappingRequestList': new FormControl(''),
    });
  }
  }

  onSubmitUser(isMail: boolean){

    // //console.log('DataService: ', this.service.customerData);
    // //console.log('FormValues: ', this.addAdminForm.value)
    if (this.addAdminForm.valid) {

      let customerAdminAddressMappingRequestListVal = this.addAdminForm.value?.customerAdminAddressMappingRequestList && JSON.parse(JSON.stringify(this.addAdminForm.value?.customerAdminAddressMappingRequestList)).map((item: { customerAddressCode: any}) => {
        return {customerAddressCode: item.customerAddressCode}
      });
      // //console.log('CustomerAdminAddressMappingRequestListVal: ', customerAdminAddressMappingRequestListVal)

      // this.service.common.progressLoader = true;
      // this.submitted = true;

      // //console.log('FormValues: ', this.addAdminForm.value);

      let reqFormData = {
        customerAdminAddressMappingRequestList: customerAdminAddressMappingRequestListVal ? customerAdminAddressMappingRequestListVal : [],
        customerCode: this.customerCode,
        adminEmail: this.addAdminForm.value?.adminEmail,
        adminName: this.addAdminForm.value?.adminName,
        phoneNumber: this.addAdminForm.value?.phoneNumber,
        // customerAdminCode: this.popupTitle === 'Edit Operational User' ? this.customerAdminCode : null,
        customerAdminCode:  this.customerAdminCode ?  this.customerAdminCode : null,
        // roleName: this.addAdminForm.value?.roleName,
          roleName: '',
        organizationCode: this.service.local.get('orgCode'),

        customerDataPermissionRequestList : this.customerPermission,
        isMail: !this.customerAdminCode && isMail ? true : null
      }
      // console.log('SubmitUserForm: ',  this.customerAdminCode, isMail, reqFormData);
      // return;
      this.service.common.progressLoader = true;
      this.service.saveOrUpdateCustomerAdmin(reqFormData, (response) => {

        //////console.log(response);
        if (response.responsecode == 200) {
          
          this.dialogRef.close('success');
          this.selectedCustomerCode = null;
          this.resetUserFormData();
          this.customerAdminCode = '' ;
          this.service.common.message = response.message;
          this.service.common.progressLoader = false;
          this.service.common.successMessage = true;
          // //console.log('saveOrUpdateCustomerAdmin:', response);
          this.getAdminListByCustomerCode(this.customerCode);
        } else {
          this.service.common.message = response.message;
          this.service.common.progressLoader = false;
          this.service.common.errorMessage = true;
        }
      })
    }
  }

  cancelPopup() {
    if (this.cancelPatient) {
      const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
        width: '350px',
        data: { msg: 'You have made some changes on the screen! Do you want to cancel the changes?' }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result != undefined) {
          // this.resetFormData();
          // this.router.navigate(['/dashboard/patient/patient-list']);
          this.router.navigate(['/dashboard/customer/customer-list']);
        }
      });
    } else {
      // this.router.navigate(['/dashboard/patient/patient-list']);
      this.router.navigate(['/dashboard/customer/customer-list']);
    }
  }

  getCust_PageAccessPermission()
  {
    this.service.getCustomerPageAccessMster().subscribe((res:any) => { 
      if(res.responsecode == 200)
      {        
         this.customerPermission = res.data;
         if(!this.customerAdminCode){
          this.customerPermission.find(ele => ele.dataPermissionName === 'View Jobs & Status').isView = true;
         }
      }
    });
  }

  chx_permissionChange(event,element){
    if(element.dataPermissionName === "View Jobs & Status"){
      if(!element.isView){
        const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
          width: '500px',
          data: { msg: "If you uncheck this, then that contact won't be able to login. Are you sure you want to change this permission?" }
        });
    
        dialogRef.afterClosed().subscribe(result => {
          if (result != undefined) {
            this.customerPermission.map(ele => ele.isView = false);
          } else {
            this.customerPermission.find(ele => ele.dataPermissionName === 'View Jobs & Status').isView = true;
          }
        });
      }
    }
    else
    {
      if(element.isView){
        this.customerPermission.find(ele => ele.dataPermissionName === 'View Jobs & Status').isView = true;
      }
    }
    
  }

  confirmEmailAvailability(
    control: FormControl
  ): Promise<any> | Observable<any> {
    const emailavaiable = new Promise<any>((resolve, reject) => {

      if(this.customerAdminCode)
      {
        this.service.common.checkEmailAvailabilityForUpdate(control.value, this.customerAdminCode).subscribe(response => {
          if (response == false) {
           resolve({ invalid: true });
         } else {
           resolve(null);
         }
       });
      }
      else
      {
        this.service.common.checkEmailAvialablity(control.value, response => {
           if (response == false) {
            resolve({ invalid: true });
          } else {
            resolve(null);
          }
        });
      }

       
    });
    return emailavaiable;
  }

}

