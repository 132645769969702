import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog } from "@angular/material/dialog";
import { CommonNotesService } from '../common-notes.service';
import { CommonApiService } from 'src/app/shared/services/common-apiService';
import { MatCheckboxChange } from '@angular/material/checkbox'; 
import { BehaviorSubject, Observable } from 'rxjs';
import { DataService } from '../threaded-note-list/data.service';
import { ConfirmLogoutComponent } from 'src/app/common/confirm-logout/confirm-logout.component';

@Component({
  selector: 'add-update-note',
  templateUrl: './add-update-note.component.html',
  styleUrls: ['./add-update-note.component.scss']
})
export class AddUpdateNoteComponent implements OnInit {
  private dataSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public data$: Observable<any> = this.dataSubject.asObservable();
  isNoteEdit: boolean;
  seletedNoteForEdit: any;
  protected notes:string = '';
  noteRequest:any = {
    isPublic: true,
    note: null
  }

  constructor(public dialog: MatDialog, public dialogRef: MatDialogRef<AddUpdateNoteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public notesService : CommonNotesService, public dataService: DataService, public _commonAPI_SVC : CommonApiService,) {}

  ngOnInit() {
    console.log('ModelData: ', this.data, this.data.noteArray);
    if(this.data?.note && this.data?.note?.isNoteEdit){
      this.isNoteEdit = true;
      this.seletedNoteForEdit = this.data;
      this.noteChangeEvent(this.data.note);
    } else {
      if(this.notesService.common.noteRequest){
        this.notes = this.notesService.common.noteRequest.note;
        this.noteRequest = this.notesService.common.noteRequest;
      }      
      setTimeout(() => { 
        document.getElementById('note_input_1').focus();
      }, 500);
    }
  }

  noteChangeEvent(event: any) {
    console.log('NoteChangeEvent_2: ', event);
      if(event?.isNoteEdit){
        this.isNoteEdit = event?.isNoteEdit;
        this.seletedNoteForEdit = event;
        this.notes = event.note;
        this.noteRequest.note = event.note;
        this.noteRequest.isPublic = event.isPublic;
        window.document.getElementById('note_input_1').scrollIntoView();
        setTimeout(() => { 
          document.getElementById('note_input_1').focus();
        }, 500);
      }
      if(event?.isNoteDelete && event.noteEntityMappingCode){
          const dialog = this.dialog.open(ConfirmLogoutComponent, {
            width: '350px',
            data: { 
              msg: 'Are you sure you want to delete this note?'
            }
          });      
      
          dialog.afterClosed().subscribe(result => {    
            if(result && result.toLowerCase() === 'ok'){ 
              this.notesService.common.progressLoader = true;
              this.notesService.softDeleteByNoteEntityMappingCode(event.noteEntityMappingCode, (response) => {
                //console.log(response);
                this.notesService.common.progressLoader = false;
                if (response.responsecode == 200) {
                  if( this.data?.noteArray?.length > 1){
                    var foundIndex =  this.data?.noteArray?.findIndex(note => note.noteEntityMappingCode === event.noteEntityMappingCode && note.entityUuid === event.entityUuid);
                    // console.log('FonndIdx: ', foundIndex);
                    if (foundIndex > -1) { // only splice array when item is found
                      this.data?.noteArray?.splice(foundIndex, 1); // 2nd parameter means remove one item only
                    }
                  } 
                  this.notesService.common.successMessage = true;
                  this.notesService.common.message = response.message;
                }
                else 
                {
                  this.notesService.common.errorMessage = true;
                  this.notesService.common.message = response.message;
                }
              });
            }
          });       
      }
    }

  toggleSelection(change: MatCheckboxChange): void {
    if (change.checked) {
      this.noteRequest.isPublic = false;
    } else {
      this.noteRequest.isPublic = true;
    }
  }

  updateNote(noteVal: any){
    delete noteVal.isNoteEdit;
    // console.log('UpdateNote: ', noteVal, this.data.noteArray); 
    // if(this.notes && this.notes.trim()){
    //   return true;
    // }
    this.noteRequest.note = this.notes;  
    const payload ={
      noteEntityMappingCode: noteVal?.noteEntityMappingCode,
      entityName: noteVal?.entityName,
      entityUuid: noteVal?.entityUuid,
      isPublic: this.noteRequest.isPublic,
      note: this.noteRequest.note,
      organizationCode: noteVal?.organizationCode
    }
    // console.log('Payload:', payload);
    // return;
    this.notesService.common.progressLoader = true;
    this._commonAPI_SVC.saveAndMapNotesEntity(payload).subscribe((res:any) =>{
      this.notesService.common.progressLoader = false;
     if(res.responsecode == 200)
      {
          if(this.data?.noteArray){
            var foundIndex = this.data?.noteArray?.findIndex(note => note.noteEntityMappingCode === noteVal.noteEntityMappingCode && note.entityUuid === noteVal.entityUuid);
            this.data.noteArray[foundIndex].note = this.noteRequest.note;
            this.data.noteArray[foundIndex].isPublic = this.noteRequest.isPublic; 
            this.notes = null;
            this.noteRequest.note = null;
            this.noteRequest.isPublic = false;
            this.isNoteEdit = false;
            } else {
              noteVal.updatedNote = this.noteRequest;
              this.dataService.setData(noteVal);
              this.dialogRef.close(noteVal);
            }
         this.notesService.common.message = res.message;
          this.notesService.common.successMessage = true;
      }
      else{
          this.notesService.common.message = res.message;
           this.notesService.common.errorMessage = true;
      }  
    });   
  }

  saveNote(noteMasterCode:any = null){

  if(!noteMasterCode && !this.notes?.trim())
    return false;
    this.noteRequest.note = this.notes ? this.notes : null;
    this.notesService.common.noteRequest = this.noteRequest;
    this.dialogRef.close(this.noteRequest);

  // let reqBody = {
  //   note: this.notes ? this.notes : null,
  //   noteMasterCode: this.data?.note?.noteMasterCode ? this.data.note.noteMasterCode : null,
  //   organizationCode: orgCode,
  //   userCode: userCode
  // }

  // console.log('NotesText: ', reqBody);
  // return;

  // this.notesService.common.progressLoader = true;
  // this.notesService.saveAndUpdateNoteMaster(reqBody).subscribe((res:any) =>{
    
  //   this.notesService.common.progressLoader = false;
        
  //   if(res.responsecode == 200)
  //   {
  //     this.dialogRef.close('success');
  //     this.notesService.common.message = res.message;
  //     this.notesService.common.successMessage = true;      
  //   }
  //   else{
  //     this.notesService.common.message = res.message;
  //     this.notesService.common.errorMessage = true;
  //   }
    
  // })
}

}
