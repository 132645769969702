<hr />
    <div class="row">
      <div class="col-4">
        <div class="row">
          <small>Shift</small><br>
          <p class="w-100 m-0">{{shiftWithJobTypeInput.jobTypeWithShiftList.startTime}}&nbsp;to &nbsp;{{shiftWithJobTypeInput.jobTypeWithShiftList.endTime}}</p>
        </div>
        <ng-template [ngIf]="allowBreaks">
        <ng-template [ngIf]="shiftWithJobTypeInput.jobTypeWithShiftList.breakList.length > 0">
          <div class="row" style="font-size: 12px;" *ngFor="let break of shiftWithJobTypeInput.jobTypeWithShiftList.breakList">
            <div class="col w-auto text-capitalize maxW_auto"> {{break.breakType}} </div>
            <div class="col w-auto"> {{break.durationMin}}&nbsp;min &nbsp; {{break.paidStatus =='Paid' ? '$'
              : ''}}</div>
          </div>
        </ng-template>
        </ng-template>
      </div>

      <ng-template [ngIf]="shiftWithJobTypeInput.jobTypeWithShiftList.required">
      <div class="col-2">
        <div class="row">
          <small>Required </small><br>
          <p class="m-0">{{shiftWithJobTypeInput.jobTypeWithShiftList.required}}</p>
        </div>
      </div>
      </ng-template>

      <ng-template [ngIf]="shiftWithJobTypeInput.jobTypeWithShiftList.scheduled">
      <div class="col-2 text-capitalize">
        <div class="row">
          <small>{{status ? status : 'Scheduled'}} </small><br>
          <p class="m-0">{{shiftWithJobTypeInput.jobTypeWithShiftList.scheduled}}</p>
        </div>
      </div>
      </ng-template>

      <ng-template [ngIf]="showClockInPIN"> 
      <ng-template [ngIf]="shiftWithJobTypeInput.jobTypeWithShiftList.clockinPin">
        <div class="col-1 text-capitalize text-center" >
          <div class="row">
            <small>PIN</small><br>
            <p class="clock-in-pin m-0">{{shiftWithJobTypeInput.jobTypeWithShiftList.clockinPin}}</p>
          </div>
        </div>
        </ng-template>
      </ng-template>

      <div class="col-3 d-flex my-auto">
        
        <ng-template [ngIf]="allowScheduledStaff_Icon">
        <ng-template [ngIf]="shiftWithJobTypeInput.jobTypeWithShiftList.jobDurationDetailsList.length > 0">
        <img title="Scheduled Staff" (click)="showScheduledStaff(
          shiftWithJobTypeInput?.customerName,
          shiftWithJobTypeInput?.jobDateFixedFormat,
          shiftWithJobTypeInput?.siteName,
          shiftWithJobTypeInput?.siteAddress,
          shiftWithJobTypeInput?.appointmentsCode,
          shiftWithJobTypeInput.jobTypeWithShiftList,
          schdeuledStaff_popUp
            )" class="p-1 notebtnIc curser-pointer pt-0 me-1" style="cursor: pointer;" src="assets/images/schedule-staff.svg" />
        </ng-template>
      </ng-template>

      <!-- shiftWithJobTypeInput.jobTypeWithShiftList.isPassedEndTime -->
      <ng-template [ngIf]="allowActions.allowToAddScheduledStaff"> 
        <img *ngIf="shiftWithJobTypeInput?.jobTypeWithShiftList?.isPassedStartTime || shiftWithJobTypeInput?.jobTypeWithShiftList?.jobDurationDetailsList[0]?.isPassedEndTime" 
        title="Add Scheduled Staff" (click)="updateClockInOutTime(shiftWithJobTypeInput)" class="p-1 notebtnIc curser-pointer pt-0 me-1" style="cursor: pointer;" src="assets/images/add-schedule-staff.svg" />
      </ng-template>

        <ng-template [ngIf]="allowActions.allowViewInfo">
          <img (click)="CardViewInfo(shiftWithJobTypeInput.jobTypeWithShiftList)" class="p-1 notebtnIc curser-pointer pt-0 me-1" style="cursor: pointer;" title="Note" src="assets/images/info-icon.svg" />
        </ng-template>

        <ng-template [ngIf]="shiftWithJobTypeInput.jobTypeWithShiftList.jobDurationDetailsList.length > 0 && (allowActions.allowRosterPrint || userType !== 'Customer')"> 
          <ng-template [ngIf]="userType">
            <img title="Roster Print" (click)="printDetails(
              shiftWithJobTypeInput?.customerName,
             shiftWithJobTypeInput?.jobDateFixedFormat,
             shiftWithJobTypeInput?.siteName,
             shiftWithJobTypeInput?.siteAddress,
             shiftWithJobTypeInput?.appointmentsCode,
             shiftWithJobTypeInput.jobTypeWithShiftList
           )" class="p-1 notebtnIc curser-pointer pt-0 me-1" style="cursor: pointer;" src="assets/images/roster-print.svg" />
          </ng-template>
          </ng-template>

        <span  *ngIf="userType !== 'Customer'" class="notebtnContainer ps-1 me-2" (click)="_commonSVC.showGroupNotes(shiftWithJobTypeInput.jobTypeWithShiftList.appointmentsProceduresCode, (shiftWithJobTypeInput.jobTypeWithShiftList.jobDateFixedFormat || shiftWithJobTypeInput.jobDateFixedFormat), shiftWithJobTypeInput.jobTypeWithShiftList.isNotesAdded)">
          <img *ngIf="shiftWithJobTypeInput?.jobTypeWithShiftList?.isNotesAdded" class="p-1 notebtnIc curser-pointer pt-0" style="cursor: pointer;" title="Note" src="assets/images/note-icon-active.svg" />
          <img *ngIf="!shiftWithJobTypeInput?.jobTypeWithShiftList?.isNotesAdded" class="p-1 notebtnIc curser-pointer pt-0" style="cursor: pointer;" title="Note" src="assets/images/note-icon.svg" />
        </span>

      </div>
      

     </div>

     <ng-container *ngIf="userType === 'Customer' || (allowActions.allowView && shiftWithJobTypeInput.jobTypeWithShiftList.isPassedStartTime === true)">
      <hr />
     </ng-container>     
    
    <div class="row">

      <div class="col-6 text-start">
         
        <ng-template [ngIf]="allowActions.allowView">
        <ng-template [ngIf]="shiftWithJobTypeInput.jobTypeWithShiftList.isPassedStartTime === true">
          <button title="Date: {{shiftWithJobTypeInput.date}}" class=" btn-jobcard me-2"
            (click)="CardViewJob(shiftWithJobTypeInput?.appointmentsCode, shiftWithJobTypeInput.jobTypeWithShiftList.appointmentsProceduresCode, shiftWithJobTypeInput.jobDateFixedFormat)">
            View
          </button>
          </ng-template>
          </ng-template>

          <ng-template [ngIf]="allowActions.allowToCopyJob">
          <button class="btn-jobcard me-2 copyBtn"
            (click)="CopyJob(shiftWithJobTypeInput?.customerCode, shiftWithJobTypeInput?.siteAddressCode, shiftWithJobTypeInput.jobTypeWithShiftList)">
            Copy
          </button>
         
        </ng-template>

        <ng-template [ngIf]="allowActions.allowEdit">
          <ng-template [ngIf]="!shiftWithJobTypeInput.jobTypeWithShiftList.isPassedStartTime">
            <button class="btn-jobcard me-2"
              (click)="CardEditJob(shiftWithJobTypeInput?.appointmentsCode, shiftWithJobTypeInput.jobTypeWithShiftList.appointmentsProceduresCode, shiftWithJobTypeInput.jobDateFixedFormat)">
              Edit
            </button>
            </ng-template>
            </ng-template>

            <ng-template [ngIf]="allowActions.allowDelete">
            <ng-template [ngIf]="!shiftWithJobTypeInput.jobTypeWithShiftList.isPassedStartTime">
            <button class="btn-jobcard"
            (click)="deleteJob(shiftWithJobTypeInput?.appointmentsCode, shiftWithJobTypeInput.jobTypeWithShiftList.appointmentsProceduresCode, shiftWithJobTypeInput?.jobDateFixedFormat)">
            Delete
          </button>
          </ng-template>
          </ng-template>


          <span class="job-copied text-success"
            *ngIf="is_JobCopied && copiedJobData?.jobData?.appointmentsProceduresCode === shiftWithJobTypeInput.jobTypeWithShiftList.appointmentsProceduresCode">
            This job has been copied* </span>
        
      </div>

      <div class="col-6 text-end">

        <ng-template [ngIf]="!shiftWithJobTypeInput.jobTypeWithShiftList.isPassedEndTime">
          <ng-template
            [ngIf]="!(shiftWithJobTypeInput.jobTypeWithShiftList?.required == shiftWithJobTypeInput.jobTypeWithShiftList?.scheduled 
                    && (status?.toLowerCase() === 'accepted' || status?.toLowerCase() === 'scheduled' ))">

          <ng-template [ngIf]="allowActions.allowAssignStaff"> 
            <!-- <button class="btn-jobcard me-1" [style.color]="shiftWithJobTypeInput.jobTypeWithShiftList.jobColor"
              (click)="openAssignPopUp(shiftWithJobTypeInput?.appointmentsCode, shiftWithJobTypeInput.jobTypeWithShiftList.appointmentsProceduresCode, shiftWithJobTypeInput.jobDateFixedFormat)">
              Assign
            </button> -->

            <button class="btn-jobcard me-2"
              (click)="openAssignPopUp(shiftWithJobTypeInput)">
              Assign
            </button>

          </ng-template>

          <ng-template [ngIf]="allowActions.allowNotifyStaff"> 
            <button class="btn-jobcard me-2"
              (click)="openNotifyPopUp(shiftWithJobTypeInput?.appointmentsCode, shiftWithJobTypeInput.jobTypeWithShiftList.appointmentsProceduresCode, shiftWithJobTypeInput.jobDateFixedFormat)">
              Notify
            </button>
            </ng-template>

            <!-- <ng-template [ngIf]="allowActions.allowToViewNotifiedStaff"> 
            <button class="btn-jobcard" [style.color]="shiftWithJobTypeInput.jobTypeWithShiftList.jobColor"
              *ngIf="userType !== 'Staff'"
              (click)="shiftWithJobTypeInput?.appointmentsCode 
                       && shiftWithJobTypeInput?.jobTypeWithShiftList.appointmentsProceduresCode 
                       && shiftWithJobTypeInput?.jobDateFixedFormat 
                       && getNotifyList('notified staff', shiftWithJobTypeInput?.appointmentsCode, shiftWithJobTypeInput.jobTypeWithShiftList.appointmentsProceduresCode, shiftWithJobTypeInput.jobDateFixedFormat)" >
              Notified Staff List
            </button>
            </ng-template> -->

          </ng-template>
        </ng-template>

          <button class="btn-jobcard"
            *ngIf="userType !== 'Staff' && allowActions.allowToViewNotifiedStaff"
            (click)="shiftWithJobTypeInput?.appointmentsCode 
                     && shiftWithJobTypeInput?.jobTypeWithShiftList.appointmentsProceduresCode 
                     && shiftWithJobTypeInput?.jobDateFixedFormat 
                     && getNotifyList('notified staff', shiftWithJobTypeInput?.appointmentsCode, shiftWithJobTypeInput.jobTypeWithShiftList.appointmentsProceduresCode, shiftWithJobTypeInput.jobDateFixedFormat)" >
            Notified Staff List
          </button>
         

      </div>
    </div>
 

  

   
 
  <ng-template #EditJob_PopUp>
    <app-new-job [EditJobmodalData]="EditJobmodalData" (event)="editJob_RefreshList($event)"></app-new-job>
  </ng-template>
  
  <ng-template #ViewInfo_PopUp>
  
    <div class="row mb-3">
      <div class="col-md-6 align-self-center">
        <h6 class="mb-0 dialog-header">Job Details</h6>
      </div>
      <div class="col-md-6 text-end">
        <button class="btn-close cursor-pointer" [mat-dialog-close]="true"></button>
      </div>
    </div>
  
    <div class="row job-details"> 

    <div class="row mb-2">
      <div class="col-3">
       Uniform
      </div>
      <div class="col-9">
       :&nbsp; {{jobInfo?.uniform ? jobInfo?.uniform : '--'}}
      </div>
    </div>
   
    <div class="row mb-2">
      <div class="col-3">
       Equipment
      </div>
      <div class="col-9">
        :&nbsp; {{jobInfo?.equipments ? jobInfo?.equipments : '--'}}
      </div>
    </div>
  
    <div class="row mb-2">
      <div class="col-3">
       Supervisor
      </div>
      <div class="col-9">
        :&nbsp; {{jobInfo?.supervisorName ? jobInfo?.supervisorName : '--'}}
      </div>
    </div>
  
    
    <div class="row mb-2">
      <div class="col-3">
        Special Instructions
      </div>
      <div class="col-9">
        :&nbsp; {{jobInfo?.specification ? jobInfo?.specification : '--'}}
      </div>
    </div>
<!-- 
    <div class="row mb-2">
      <div class="col-3">
        Clock-In PIN
      </div>
      <div class="col-9">
        :&nbsp; {{'--'}}
      </div>
    </div> -->

  </div>
  
  </ng-template>
  
  <ng-template #ViewJobDetails_PopUp>
    <app-view-job-details [Job_ModalData]="View_JobmodalData" (event)="dialogRef.close();"></app-view-job-details>
  </ng-template>
  
  
  <ng-template #schdeuledStaff_popUp>
    <div class="row">
      <div class="col-md-6 align-self-center">
        <h6 class="dialog-header pt-2">Scheduled Staff</h6>
      </div>
      <div class="col-md-6">
        <div class="row justify-content-end">
          <button class="btn-close cursor-pointer" matDialogClose></button>
        </div>
      </div>
    </div>
  
    <div class="row">
 
    <app-common-card 
    [job_shortData_Modal]="job_shortData_Modal"
    (emitter_handleEvent)="refreshData($event)">
  </app-common-card>
  
    </div>
  </ng-template>
  
  <ng-template #jobPaste_calendar>
    <div class="row">
      <div class="col-md-6 align-self-center">
        <h6 class="dialog-header pt-2">Paste Job</h6>
      </div>
      <div class="col-md-6">
        <div class="row justify-content-end">
          <button class="btn-close cursor-pointer" (click)="cancelCopyPaste()"></button>
        </div>
      </div>
    </div>
     <div class="row">
      <span class="mb-2 pasteJobTxt">Choose date to paste the job</span>
      <mat-calendar  (selectedChange)="JobPasteDateChange($event)" [minDate]="pastDate"></mat-calendar>
       <!-- <mat-calendar [(selected)]="jobPaste_date" (selectedChange)="JobPasteDateChange($event)"></mat-calendar> -->
     </div>
  </ng-template>



  <ng-template #update_clock_in_out_time>
    
    <!-- <div class="row">
      <div class="col-md-6 align-self-center">
       </div>
      <div class="col-md-6">
        <div class="row justify-content-end">
          <button class="btn-close cursor-pointer" (click)="closePopup()"></button>
        </div>
      </div>
    </div> -->
  
    <div class="card-body">
 
    <app-update-clock-in-out-time
    [missed_clock_in_out_inputs]="missed_clock_in_out_inputs"
    [eligibleStaff]="staffs_for_clock_in_out"
    [isNewStaff]="true"
    (getFormData)="update_missed_clock_in_out_data($event)"
     ></app-update-clock-in-out-time>

    </div>
  
   </ng-template>




   <ng-template #roster_print_popUp>
    <div class="row">
      <div class="col-md-6 align-self-center">
        <h6 class="dialog-header pt-2">Roster</h6>
      </div>
      <div class="col-md-6">
        <div class="row justify-content-end">
          <button class="btn-close cursor-pointer" matDialogClose></button>
        </div>
      </div>
    </div>
  
    <div class="row">
 
    <app-roster-print
    [job_shortData_Modal]="job_shortData_Modal"
    (emitter_handleEvent)="refreshData($event)">
  </app-roster-print>
  
    </div>
  </ng-template>