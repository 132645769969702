import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SessionStorageService } from 'angular-web-storage';
import { ApiRootService } from 'src/app/shared/api-root.service';
import { CommonService } from 'src/app/shared/common.service';
import { IApiResponse } from 'src/app/shared/interface/core.interface';
import { CommonApiService } from 'src/app/shared/services/common-apiService';

interface IStaffShortDts {
  staffLogo: string;
  staffCode: string;
 // satffSkillResposeList?: (null)[] | null;
  email?: null;
  staffName: string;
  staffPhone?: null;
  workerLicenceResList : any[] | null,
  workerCertificationResList :any[] | null
}

interface IDialogData{
  staffCode:string
}

@Component({
  selector: 'st-staff-detail',
  templateUrl: './st-staff-detail.component.html',
  styleUrls: ['./st-staff-detail.component.scss']
})

export class StStaffDetailComponent implements OnInit {

  // @Input() staffCode : string;
   staffCode : string;
   img_serverPath: string = '';

   defaultValue : IStaffShortDts ={
    staffLogo : '',
    staffCode : '',
    workerLicenceResList : null,
    workerCertificationResList : null,
    email : null,
    staffName : '',
    staffPhone : null,
   }

  staffDetails : IStaffShortDts = this.defaultValue ;
  isLoading:boolean = false;

  constructor(
    public dialogRef: MatDialogRef<StStaffDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData : IDialogData,
     private commonAPIService : CommonApiService,
    private storageService : SessionStorageService,
    private rootApiService :  ApiRootService,
    private commonService : CommonService
  ){}

  ngOnInit(): void 
  {
    this.img_serverPath = `${this.rootApiService.getSuperAdmin()}/downloadFile/`;

  
    if(this.dialogData.staffCode)
    {
      this.getStaffShortDetails();
    }
    
    
  }


  getStaffShortDetails()
  {
    this.isLoading = true ;
    this.commonAPIService.getStaffDetailsForCustomerByWorkerCode(
      this.dialogData.staffCode,
      this.storageService.get("orgCode")
    )
    .subscribe((res:IApiResponse)=>{
      this.isLoading = false ;
      if(res.responsecode == 200)
      {
        this.staffDetails =  (res.data as IStaffShortDts) ;
        
      }
      else{
        this.staffDetails = this.defaultValue;
      }
    })
  }

  closeDialog(){
    this.dialogRef.close();
  }


    
  convertFileToBase64(url: string): Promise<string> {
    return fetch(url)
      .then(response => response.blob())
      .then(blob => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result as string);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      }));
  }


  viewFiles(fileUrl:string) 
  {
    this.commonService.progressLoader = true;
    const a = document.createElement('a');
    a.href = this.img_serverPath+fileUrl;
    a.download = fileUrl.split('.')[0];
  
     document.body.appendChild(a);
     a.click();
    document.body.removeChild(a);
    this.commonService.progressLoader = false;

    // this.commonService.progressLoader = true;
    // this.convertFileToBase64(this.img_serverPath+fileUrl).then(base64Data => {
    //   this.commonService.progressLoader = false;
    //   const windowFeatures = 'width=600,height=400,left=200,top=100';
    //   const newWindow = window.open('', '_blank', windowFeatures);
      
    //   if (newWindow) {
    //     newWindow.document.write(`
    //       <html>
    //         <head><title>File Viewer</title></head>
    //         <body>
    //           <iframe src="${base64Data}" width="100%" height="100%" style="border:none;"></iframe>
    //         </body>
    //       </html>
    //     `);
    //   }
    // });
  }


  formateFile(filename:string)
  {
 
   if(!filename) return

   let a =  filename.split(".")[0].split("-");
   let pop = a.pop();

  return `${a.join("-")}.${filename.split(".")[1]}`
 
  }


}
