
<ng-template [ngIf]="tilesGrid">
<div class="row justify-content-around  shortcuts  card-status">
           
    <div class="row card-justify pe-0 card-justify">

        <ng-template [ngIf]="permissionFor_NewJobShortCut.addPermission === true">
        <div class="col-md-2 col-sm-6 card-shortcut-inner status-grey mr-2" (click)="openShortcutModal(true, 'New Job', newJobTempRef)">
            <div class="text-center">
                <div class="assigned const-height align-items-center pt-2 ">
                    <div class="icon-round">
                        <img src="../../../assets/icons/svg/new-job.svg" alt="">
                    </div>
                    <div class="mt-2">
                        <h5 class="status">New Job</h5>
                    </div>
                </div>
            </div>
        </div>
        </ng-template>

        <ng-template [ngIf]="permissionFor_NewShiftShortCut.addPermission === true">
        <div class="col-md-2 card-shortcut-inner  status-grey" (click)="openShortcutModal(true, 'New Shift', null)">
            <div class="text-center">
                <div class="assigned const-height align-items-center  pt-2">
                    <div class="icon-round">
                        <img src="../../../assets/icons/svg/new-shift.svg" alt="">
                    </div>
                    <div class="mt-2">
                        <h5 class="status">New Shift</h5>
                    </div>
                </div>
            </div>
        </div>
        </ng-template>

        <ng-template [ngIf]="permissionFor_CustControlShortCut.addPermission === true">
        <div class="col-2 card-shortcut-inner status-grey" (click)="openShortcutModal(true, 'New Location', newLocationTempRef)">
            <div class="text-center">
                <div class="assigned const-height align-items-center  pt-2">
                    <div class="icon-round">
                        <img src="../../../assets/icons/svg/new-location.svg" alt="">
                    </div>
                    <div class="mt-2">
                        <h5 class="status">New {{ 'main.labels.Job Site' | translate }}</h5>
                    </div>
                </div>
            </div>
        </div>
        </ng-template>

        <ng-template [ngIf]="permissionFor_CustControlShortCut.addPermission === true">
        <div class="col-2 card-shortcut-inner status-grey" (click)="openShortcutModal(true, 'New Contact', null)">
            <div class="text-center">
                <div class="assigned const-height align-items-center  pt-2">
                    <div class="icon-round">
                        <img src="../../../assets/icons/svg/new-contact.svg" alt="">
                    </div>
                    <div class="mt-2">
                        <h5 class="status">New Contact</h5>
                    </div>
                </div>
            </div>
        </div>
        </ng-template>

        <!-- <div class="col-2 card-shortcut-inner status-grey" (click)="getStatus('pending', 'pending')"> -->
            <div class="col-2 card-shortcut-inner status-grey" (click)="getStatus('pending', 'pending')">
            <div class="text-center">
                <div class="assigned const-height align-items-center  pt-2">
                    <div class="icon-round">
                        <img src="../../../assets/icons/svg/post-notice.svg" alt="">
                    </div>
                    <div class="mt-2">
                        <h5 class="status">Notify</h5>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-2 card-shortcut-inner status-grey" routerLink="/dashboard" [queryParams]="{chat: true}">
            <div class="text-center">
                <div class="assigned const-height align-items-center  pt-2">
                    <div class="icon-round">
                        <img src="../../../assets/icons/svg/send-chat.svg" alt="">
                        <span *ngIf="common?.globalChatCount > 0" class="position-absolute translate-middle badge rounded-pill bg-danger">
                          {{common?.globalChatCount}}
                        </span>
                    </div>
                    <div class="mt-2">
                        <h5 class="status">
                          <span>Chat</span>
                        </h5>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-2 card-shortcut-inner status-grey" (click)="openShortcutModal(true, 'Approve Leave', approveLeaveTempRef)">
            <div class="text-center">
                <div class="assigned const-height align-items-center  pt-2">
                    <div class="icon-round">
                        <img src="../../../assets/icons/svg/approve-leave.svg" alt="">
                        <span *ngIf="common?.globalLeaveCount > 0" class="position-absolute translate-middle badge rounded-pill bg-danger">
                          {{common?.globalLeaveCount}}
                        </span>
                    </div>
                    <div class="mt-2">
                        <h5 class="status">Approve Leave</h5>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-2 card-shortcut-inner status-grey" (click)="openShortcutModal(true, 'Missed Clock-Out', missedClockOut_TempRef)">
          <div class="text-center">
              <div class="assigned const-height align-items-center  pt-2">
                  <div class="icon-round">
                      <img src="../../../assets/icons/svg/checked-out.svg" alt="">
                      <span *ngIf="missedClockOut_Count > 0" class="position-absolute translate-middle badge rounded-pill bg-danger">
                        {{missedClockOut_Count}}
                      </span>
                  </div>
                  <div class="mt-2">
                      <h5 class="status">Missed Time Card</h5>
                  </div>
              </div>
          </div>
      </div>

      <div class="col-2 card-shortcut-inner status-grey mr-2" (click)="openShortcutModal(true, 'RequestedNewJobs', newJobRequestList_TempRef)">
        <div class="text-center">
            <div class="assigned const-height align-items-center  pt-2">
                <div class="icon-round">
                  <img src="../../../assets/icons/svg/new-job.svg" alt="">
                    <span *ngIf="newJobRequest_Count > 0" class="position-absolute translate-middle badge rounded-pill bg-danger">
                      {{newJobRequest_Count}}
                    </span>
                </div>
                <div class="mt-2">
                    <h5 class="status">Requested Jobs</h5>
                </div>
            </div>
        </div>
    </div>

    <ng-template [ngIf]="permissionFor_NewJobShortCut.addPermission === true">
      <div class="col-md-2 col-sm-6 card-shortcut-inner status-grey" (click)="openShortcutModal(true, 'CancelJobApprovelRequest', CancelJobApprovelRequest_TempRef)">
          <div class="text-center">
              <div class="assigned const-height align-items-center pt-2 ">
                  <div class="icon-round">
                      <img src="../../../assets/icons/svg/cancelled.svg" alt="">
                      <!-- <span *ngIf="common?.globalCancelJobRequestCount > 0" class="position-absolute translate-middle badge rounded-pill bg-danger">
                        {{common?.globalCancelJobRequestCount}}
                      </span> -->
                      <span *ngIf="requestCancel_Count > 0" class="position-absolute translate-middle badge rounded-pill bg-danger">
                        {{requestCancel_Count}}
                      </span>
                  </div>
                  <div class="mt-2">
                      <h5 class="status">Request Cancel</h5>
                  </div>
              </div>
          </div>
      </div>
      </ng-template>

    </div>
</div>
</ng-template>


<ng-template [ngIf]="iconsGrid">
    <div class="row justify-content-around  shortcuts  card-status">
           
        <div class="row card-justify pe-0 card-justify">
    
            <ng-template [ngIf]="permissionFor_NewJobShortCut.addPermission === true">
              <div class="col text-center" matTooltip="New Job" (click)="openShortcutModal(true, 'New Job', newJobTempRef)">
                    <div class="assigned const-height align-items-center  ">
                        <div class="icon-round">
                            <img src="../../../assets/icons/svg/new-job.svg" alt="">
                        </div>
                        <div class="mt-2" *ngIf="router.url === '/dashboard'">
                          <h5 class="status">New Job</h5>
                      </div>
                       </div>
                </div>
             </ng-template>
    
            <ng-template [ngIf]="permissionFor_NewShiftShortCut.addPermission === true">
             <div class="col text-center" matTooltip="New Shift" (click)="openShortcutModal(true, 'New Shift', null)">
                    <div class="assigned const-height align-items-center  ">
                        <div class="icon-round">
                            <img src="../../../assets/icons/svg/new-shift.svg" alt="">
                        </div>
                        <div class="mt-2" *ngIf="router.url === '/dashboard'">
                          <h5 class="status">New Shift</h5>
                      </div>
                      </div>
             </div>
            </ng-template>
    
            <ng-template [ngIf]="permissionFor_CustControlShortCut.addPermission === true">
              <div class="col text-center" matTooltip="New {{ 'main.labels.Job Site' | translate }}" (click)="openShortcutModal(true, 'New Location', newLocationTempRef)">
                    <div class="assigned const-height align-items-center  ">
                        <div class="icon-round">
                            <img src="../../../assets/icons/svg/new-location.svg" alt="">
                        </div>
                        <div class="mt-2" *ngIf="router.url === '/dashboard'">
                            <h5 class="status">New {{ 'main.labels.Job Site' | translate }}</h5>
                        </div>
                    </div>
             </div>
            </ng-template>
    
            <ng-template [ngIf]="permissionFor_CustControlShortCut.addPermission === true">
              <div class="col text-center" matTooltip="New Contact" (click)="openShortcutModal(true, 'New Contact', null)">
                    <div class="assigned const-height align-items-center  ">
                        <div class="icon-round">
                            <img src="../../../assets/icons/svg/new-contact.svg" alt="">
                        </div>
                        <div class="mt-2" *ngIf="router.url === '/dashboard'">
                          <h5 class="status">New Contact</h5>
                      </div>
                    </div>
                </div>
            </ng-template>
    
            <!-- <div class="col-2 card-shortcut-inner status-grey" (click)="getStatus('pending', 'pending')"> -->
                <!-- <div class="col-2 card-shortcut-inner status-grey">
                <div class="text-center">
                    <div class="assigned const-height align-items-center  pt-2">
                        <div class="icon-round">
                            <img src="../../../assets/icons/svg/post-notice.svg" alt="">
                        </div>
                        <div class="mt-2">
                            <h5 class="status">Notify</h5>
                        </div>
                    </div>
                </div>
            </div> -->
     
             <div class="col text-center" matTooltip="Approve Leave" (click)="openShortcutModal(true, 'Approve Leave', approveLeaveTempRef)">
                    <div class="assigned const-height align-items-center  ">
                        <div class="icon-round">
                            <img src="../../../assets/icons/svg/approve-leave.svg" alt="">
                            <span *ngIf="common?.globalLeaveCount > 0" class="position-absolute translate-middle badge rounded-pill bg-danger">
                              {{common?.globalLeaveCount}}
                            </span>
                        </div>
                        <div class="mt-2" *ngIf="router.url === '/dashboard'">
                          <h5 class="status">Approve Leave</h5>
                      </div>
                     </div>
                </div>
          
    
        <div class="col text-center" matTooltip="Missed Time Card" (click)="openShortcutModal(true, 'Missed Clock-Out', missedClockOut_TempRef)">
                  <div class="assigned const-height align-items-center  ">
                      <div class="icon-round">
                          <img src="../../../assets/icons/svg/checked-out-grey.svg" alt="">
                          <span *ngIf="missedClockOut_Count > 0" class="position-absolute translate-middle badge rounded-pill bg-danger">
                            {{missedClockOut_Count}}
                          </span>
                      </div>
                      <div class="mt-2" *ngIf="router.url === '/dashboard'">
                        <h5 class="status">Missed Time Card</h5>
                    </div>
                   </div>
              </div>
 
    
        <div class="col text-center" matTooltip="Requested Jobs" (click)="openShortcutModal(true, 'RequestedNewJobs', newJobRequestList_TempRef)" >
                <div class="assigned const-height align-items-center  ">
                    <div class="icon-round">
                      <img src="../../../assets/icons/svg/new-job.svg" alt="">
                        <span *ngIf="newJobRequest_Count > 0" class="position-absolute translate-middle badge rounded-pill bg-danger">
                          {{newJobRequest_Count}}
                        </span>
                    </div> 
                    <div class="mt-2" *ngIf="router.url === '/dashboard'">
                      <h5 class="status">Requested Jobs</h5>
                  </div>
                  </div>
            </div>
 
    
        <ng-template [ngIf]="permissionFor_NewJobShortCut.addPermission === true">
          
            <div class="col text-center" matTooltip="Request Cancel" (click)="openShortcutModal(true, 'CancelJobApprovelRequest', CancelJobApprovelRequest_TempRef)">
                  <div class="assigned const-height align-items-center  ">
                      <div class="icon-round">
                          <img src="../../../assets/icons/svg/cancelled-grey.svg" alt="">
                           <span *ngIf="requestCancel_Count > 0" class="position-absolute translate-middle badge rounded-pill bg-danger">
                            {{requestCancel_Count}}
                          </span>
                      </div>
                      <div class="mt-2" *ngIf="router.url === '/dashboard'">
                        <h5 class="status">Request Cancel</h5>
                    </div>
                    </div>
             </div>
          </ng-template>
    
          <!-- <div class=" col text-center" matTooltip="Chat" (click)="openShortcutModal(true, 'Chat', null)"> -->
          <div class=" col text-center" matTooltip="Chat" (click)="openShortcutModal(true, 'Chat', null)">
            <div class="assigned const-height align-items-center  ">
                <div class="icon-round">
                    <img src="../../../assets/icons/svg/send-chat.svg" alt="">
                    <span *ngIf="common?.globalChatCount > 0" class="position-absolute translate-middle badge rounded-pill bg-danger">
                      {{common?.globalChatCount}}
                    </span>
                </div>

                <div class="mt-2" *ngIf="router.url === '/dashboard'">
                  <h5 class="status">Chat</h5>
              </div>
              
            </div>
        </div>

        </div>
    </div>
</ng-template>






<!-- New Job Popup Start -->
<ng-template #newJobTempRef>
    <div class="row">
       <div class="col-md-6 align-self-center">
         <h6 class="dialog-header pt-2">New Job</h6>
       </div>
       <div class="col-md-6">
         <div class="row justify-content-end">
           <button class="btn-close cursor-pointer" (click)="closePopUp('newJob')"></button>
         </div>
       </div>
     </div> 
 
     <div class="row shortcutPopupScrollBody">
      <app-new-job [isShortcutModalOpen]="true"></app-new-job>
     </div>
   </ng-template>
   <!-- New Job Popup End -->

   <!-- New Location Popup Start -->
  <ng-template #newLocationTempRef>
    <div class="row  ">
       <div class="col-md-6 align-self-center">
         <h6 class="dialog-header pt-2">New {{ 'main.labels.Job Site' | translate }}</h6>
       </div>
       <div class="col-md-6">
         <div class="row justify-content-end">
           <button class="btn-close cursor-pointer" (click)="closePopUp('newLocation')"></button>
         </div>
       </div>
     </div> 
 
     <div class="row shortcutPopupScrollBody">
      <add-location [isShortcutModalOpen]="'true'"></add-location>
     </div>
   </ng-template>
   <!-- New Location Popup End -->

  <!-- Approve Leave Popup Start -->
  <ng-template #approveLeaveTempRef>
    <div class="row  ">
       <div class="col-md-6 align-self-center">
         <h6 class="dialog-header pt-2">Approve Leave</h6>
       </div>
       <div class="col-md-6">
         <div class="row justify-content-end">
           <button class="btn-close cursor-pointer" (click)="closePopUp('approveLeave')"></button>
         </div>
       </div>
     </div> 
 
     <div class="row shortcutPopupScrollBody">
      <app-leave [isShortcutModalOpen]="'true'"></app-leave>
     </div>
   </ng-template>
   <!-- Approve Leave Popup End -->


    <ng-template #shiftDetailPopup>
  
        <div class="row mb-4">
          <div class="col-md-6 align-self-center">
            <h4 class="mb-0">{{ popupTitle }}</h4>
          </div>
          <div class="col-md-6 text-end">
            <button class="btn-close cursor-pointer" [mat-dialog-close]="true"></button>
          </div>
        </div>
        
        <div class="row">
        
          <div class="w-100 mb-3">
        
            <!-- <div class="d-flex flex-row mb-3 border-bottom">
              <mat-icon class="cursor-pointer search-icon mt-2">search</mat-icon>
              <input autocomplete="off" type="text" placeholder="{{
                                'dashboard.labels.Search by Patient or Technician name' | translate }}"
                class="searchfilter form-control" name="search-name" [(ngModel)]="searchText">
            </div> -->
        
            <div class="popup-viewport-body" *ngIf="shiftStatusList?.length > 0" clickOutside (clickOutside)="showPopup = ''">
              <div *ngFor="let shift of shiftStatusList | searchFilter : searchText:['workerName', 'email']; let idx = index" class="example-item">
                <mat-card class="card-appointment mt-4 mb-4" *ngIf="shift?.workerName && shift?.email">

                  <div class="row icon-round">
                    <div class="col-md-1" *ngIf="!shift?.workerLogo">
                      <i class="material-icons icon-appointment assigned" *ngIf="popupTitle === 'Scheduled'">memory</i>
                      <i class="material-icons icon-appointment accepted" *ngIf="popupTitle === 'Checked-In'">memory</i>
                      <i class="material-icons icon-appointment expired" *ngIf="popupTitle === 'Not Checked-In' || popupTitle === 'Not Clocked-In'">memory</i>
                      <i class="material-icons icon-appointment late or cancelled" *ngIf="popupTitle === 'Checked-Out'">memory</i>
                    </div>
                    <div class="col-md-2" *ngIf="shift?.workerLogo">
                      <div class="avatarImg" [style.background-image]="'url(' + dashboarService.service.getSuperAdmin() + '/downloadFile/' + shift.workerLogo + ')'"></div>
                    </div>
                    <div class="col-md-9 default">
                      <div>
                        <span><profile-avatar-with-name [userDetail]="shift" [isRowNameWithLogo]="false" [popoverShowFor]="'staff'" [isProfileButtonEnable]="true" [isChatButtonEnable]="true"></profile-avatar-with-name></span>
                      </div>
                      <small>{{shift?.email}}</small>
                    </div>
                    <div class="col-md-1 pt-3">
                      <p><span class="badge bg-secondary color-white">{{shift?.count}}</span></p>
                    </div>
                  </div>     

                </mat-card>
              </div>
            </div>
            <div  *ngIf="shiftStatusList?.length === 0" class="mt-4 text-danger">
              <!-- {{ 'dashboard.labels.No appointments are present' | translate }}. -->
              No Records Found
            </div>
        
          </div>
        </div>
      </ng-template>

      <ng-template #statusDetailsPopUp>
  
        <div class="row mb-4">
          <div class="col-md-6 align-self-center">
            <h4 class="mb-0">{{ popupTitle }}</h4>
          </div>
          <div class="col-md-6 text-end">
            <button class="btn-close cursor-pointer" [mat-dialog-close]="true"></button>
          </div>
        </div>
        
        <div class="row container overflow-auto">
          <table class="table table-nowrap mb-0">
            <thead>
                <tr>
                    <th class="nowrapTxt">Staff Name</th>
                    <th class="nowrapTxt">Customer Name</th>
                    <th>{{ 'main.labels.Job Site' | translate }}</th>
                    <th class="nowrapTxt">Job Type</th>
                    <th class="nowrapTxt">Start Time</th>
                    <th class="nowrapTxt">End Time</th>
                    <th>Reason</th>
                </tr>
            </thead>
            <tbody>
              <ng-template [ngIf]="DecliedAndLateList.length > 0"  >
                <tr *ngFor="let element of DecliedAndLateList">
                    <td class="nowrapTxt">{{element.staffName}}</td>
                    <td class="nowrapTxt">
                      <span>
                        <profile-avatar-with-name [userDetail]="element" [isRowNameWithLogo]="false" [popoverShowFor]="'customer'" [isProfileButtonEnable]="true" [isChatButtonEnable]="false"></profile-avatar-with-name>
                     </span>
                    </td>
                    <td [title]="element.customerAddress">{{element.siteName}}</td>
                    <td class="nowrapTxt">{{element.jobType}}</td>
                    <td class="nowrapTxt">{{element.startDate}}</td> 
                    <td class="nowrapTxt">{{element.endDate}}</td>
                    <td>{{element.reason}}</td>
                </tr>
              </ng-template>

              <ng-template [ngIf]="DecliedAndLateList.length == 0">
                <tr><td colspan="7" class="text-danger">
                  No Record Found
                </td></tr>
              </ng-template>
              
           </tbody>
        </table>
        </div>
      </ng-template>


      <!-- missed clock-out popup starts -->
          <ng-template #missedClockOut_TempRef>
        <div class="row">
           <!-- <div class="col-md-6 align-self-center">
             <h6 class="dialog-header pt-2">Approve Leave</h6>
           </div> -->
           <div class="col-md-6">
             <div class="row justify-content-end">
               <button class="btn-close cursor-pointer" (click)="closePopUp('missedClockOut')"></button>
             </div>
           </div>
         </div> 
     
         <div class="row shortcutPopupScrollBody">
         <app-check-out-missed-list></app-check-out-missed-list>
         </div>
       </ng-template>
       <!-- missed clock-out popup ends -->


             <!-- new job request list popup starts -->
             <ng-template #newJobRequestList_TempRef>
              
              <div class="row  ">
                <div class="col-md-6 align-self-center">
                  <h6 class="dialog-header pt-2">Requested Job List</h6>
                </div>
                <div class="col-md-6">
                  <div class="row justify-content-end">
                    <button class="btn-close cursor-pointer" (click)="closePopUp('requestedNewJob')"></button>
                  </div>
                </div>
              </div> 
           
              <div class="row shortcutPopupScrollBody">

                <mat-tab-group
                dynamicHeight
                class="tab-button-auto-width"
                (selectedTabChange)="requestedJob_tabChanged($event)"
              >
              
                <mat-tab label="Requested">
                  <ng-template [ngIf]="tabAtciveFor === 'Requested'">    
                    <div class="w-100 mt-3">      
                      <app-admin-requested-new-job-list [componentFor]="'for requested job'" [isExpired]="false"></app-admin-requested-new-job-list>
                    </div>
                  </ng-template>
                </mat-tab>
                
                <mat-tab label="Processed">
                  <ng-template [ngIf]="tabAtciveFor === 'Processed'">
                    <div class="w-100 mt-3">
                      <app-admin-requested-new-job-list [componentFor]="'for processed job'" [appoinment_Status]="'Processed'"></app-admin-requested-new-job-list>
                    </div>        
                  </ng-template>
                </mat-tab>

                <mat-tab label="Expired">
                  <ng-template [ngIf]="tabAtciveFor === 'Expired'">
                    <div class="w-100 mt-3">
                      <app-admin-requested-new-job-list [componentFor]="'for expired job'" [isExpired]="true"></app-admin-requested-new-job-list>
                    </div>        
                  </ng-template>
                </mat-tab>

              </mat-tab-group>
             </div>
             </ng-template>
             <!-- new job request list popup ends -->
             
             <!-- Cancel Job Approvel Request list popup starts -->
             <ng-template #CancelJobApprovelRequest_TempRef>
              
              <div class="row  ">
                <div class="col-md-6 align-self-center">
                  <h6 class="dialog-header pt-2">Request Cancel</h6>
                </div>
                <div class="col-md-6">
                  <div class="row justify-content-end">
                    <button class="btn-close cursor-pointer" (click)="closePopUp('requestCancel')"></button>
                  </div>
                </div>
              </div> 
           
               <div class="row shortcutPopupScrollBody">
      
                <mat-tab-group
                dynamicHeight
                class="tab-button-auto-width"
                (selectedTabChange)="cancelJob_tabChanged($event)"
              >
              
                <mat-tab label="Pending">
                  <ng-template [ngIf]="cancelJob_activeTab === 'Pending'">    
                    <div class="w-100 mt-3">      
                       <cancel-jobs 
                      [isExpired]="false"
                      [rendered_componentFor]="'pending'" >
                  </cancel-jobs>
                    </div>
                  </ng-template>
                </mat-tab>
                
                <mat-tab label="Processed">
                  <ng-template [ngIf]="cancelJob_activeTab === 'Processed'">
                    <div class="w-100 mt-3">
                      <cancel-jobs 
                       [rendered_componentFor]="'processed'" >
                  </cancel-jobs>
                    </div>        
                  </ng-template>
                </mat-tab>

                <mat-tab label="Expired">
                  <ng-template [ngIf]="cancelJob_activeTab === 'Expired'">
                    <div class="w-100 mt-3">
                      <cancel-jobs 
                      [isExpired]="true"
                      [rendered_componentFor]="'expired'" >
                  </cancel-jobs>
                     </div>        
                  </ng-template>
                </mat-tab>

              </mat-tab-group>

             </div>
             </ng-template>
             <!-- Cancel Job Approvel Request list popup ends -->